import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "./Missing.module.scss";
import NavBar from "./home/NavBar.jsx";
import { Helmet } from "react-helmet";
import Footer from "./home/Footer.jsx";

class Missing extends Component {
  goHome() {
    this.props.history.push("/");
  }

  renderHelmet() {
    return (
      <Helmet>
        <title>Oh no! Nothing here</title>
      </Helmet>
    );
  }

  render() {
    return (
      <div>
        <NavBar />
        <div className={styles.missing}>
          <div>
            <div>
              <img
                className={styles.imageContainer}
                alt="spaceman"
                src="https://i.imgur.com/2fGbhpQ.png"
              ></img>
            </div>
            <div className={styles.header}>OH, NO </div>
            <div>That page doesn't exist, but you do! </div>
            <div className={styles.bigButton} onClick={() => this.goHome()}>
              Go Home
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default withRouter(Missing);
