const user = (state = {}, action) => {
  switch (action.type) {
    case "SET_TOKEN":
      return { ...state, token: action.token };
    case "SET_USER":
      return { ...state, ...action.userData.userDetails };
    case "LOG_OUT":
      return {};
    case "LOCALLY_UPGRADE_USER":
      return { ...state, accountType: 1 };
    default:
      return state;
  }
};

export default user;
