import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import HumanNavBar from ".//HumanNavBar.jsx";
import styles from "./DownloadAudio.module.scss";
import { Helmet } from "react-helmet";

class ThankYou extends Component {
  renderHelmet() {
    return (
      <Helmet>
        <title>Thank You for getting the Human Sounds Library!</title>
        <meta
          name="description"
          content="Thank You for getting the Human Sounds Library!  "
        />
        <meta
          property="og:url"
          content="https://www.failflow.com/humansounds/bG9sb2xvbG9sc2VjdXJpdHk"
        />
        <meta
          property="og:title"
          content="Thank You for getting the Human Sounds Library!"
        />
        <meta
          property="og:description"
          content="Thank You for getting the Human Sounds Library!"
        />
        <meta property="og:image" content="https://i.imgur.com/djWAckr.png" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/djWAckr.png"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/djWAckr.png"
        />
      </Helmet>
    );
  }

  render() {
    return (
      <div>
        {this.renderHelmet()}
        <HumanNavBar />
        <div className={styles.thankYouContainer}>
          <div className={styles.header}>Thank You! You're all set</div>
          <div className={styles.subHeader}>
            You can use the Download button below - we're excited to hear what
            you'll use the Human Sounds for. If you have any questions or ideas,
            let us know:{" "}
            <a href="mailto:shah@failflow.com">shah@failflow.com</a>
          </div>
          <a href="https://storage.googleapis.com/failflow/humanSounds.zip">
            <div className={styles.bigButton}>Download Library</div>
          </a>
        </div>
        <div className={styles.imageContainer}>
          <img
            className={styles.natureImage}
            alt="Look!"
            src="https://i.imgur.com/djWAckr.png"
          ></img>
        </div>

        <div
          className={styles.bigButton}
          onClick={() => this.props.history.push("/humanSounds")}
        >
          Go to Human Sounds
        </div>
      </div>
    );
  }
}

export default withRouter(ThankYou);
