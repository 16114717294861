import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "./HNView.module.scss";
import HNNavBar from "./HNNavBar.jsx";
import { Helmet } from "react-helmet";
import Footer from "../home/Footer.jsx";
import { bindActionCreators } from "redux";
import { logOut } from "../../actions/user";
import { connect } from "react-redux";
import { getWrapper } from "../../helpers/fetch";

const mapStateToProps = (state) => ({
  user: state.user,
  fullState: state,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ logOut }, dispatch);

class HNTrackContainer extends Component {
  constructor() {
    super();
    this.state = {
      hnItem: {},
      hnPost: {},
      allTopLevelComments: [],
      topLevelCommentsWithoutResponse: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.getHnItem();
  }

  async getHnItem() {
    const { user } = this.props;

    // this is to get the tracker info itself
    let hnItem = await getWrapper(
      "GET",
      `/api/getView/${this.props.match.params.id}`,
      {
        Authorization: user.token,
      }
    );

    this.setState({ hnItem });
    if (!hnItem || !hnItem.item) return;
    const url = hnItem.item.link;

    const params = this.getParams(url);

    const fetchUrl = `https://hacker-news.firebaseio.com/v0/item/${params.id}.json?print=pretty`;

    await fetch(fetchUrl)
      .then((res) => res.json())
      .then((json) => {
        this.setState({ hnPost: json });
      });

    let topLevelComments = this.state.hnPost.kids;

    topLevelComments.forEach((item) => {
      // let's go through the top comments and see if response by author
      let fetchUrl = `https://hacker-news.firebaseio.com/v0/item/${item}.json?print=pretty`;
      fetch(fetchUrl)
        .then((res) => res.json())
        .then((json) => {
          const comment = json;
          const allTopLevelComments = [...this.state.allTopLevelComments];
          allTopLevelComments.push(comment);
          this.setState({ allTopLevelComments });
          const responses = comment.kids;
          if (this.state.loading) {
            this.setState({ loading: false });
          }

          if (responses && responses) {
            responses.forEach((response) => {
              const responseItemUrl = `https://hacker-news.firebaseio.com/v0/item/${response}.json?print=pretty`;
              fetch(responseItemUrl)
                .then((res) => res.json())
                .then((json) => {
                  if (this.state.hnPost.by === json.by) {
                    let currentTopComments = this.state.allTopLevelComments;
                    let newTopLevel = currentTopComments.filter(
                      (el) => el.id !== comment.id
                    );
                    this.setState({ allTopLevelComments: newTopLevel });
                  }
                });
            });
          }
        });
    });
  }

  getParams(url) {
    var params = {};
    var parser = document.createElement("a");
    parser.href = url;
    var query = parser.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  }

  renderHelmet() {
    return (
      <Helmet>
        <title>Hacker News Release Dashboard</title>
        <meta
          name="description"
          content="Release, track and measure a Hacker News post or release"
        />
        <meta
          name="keywords"
          content="Hacker news, comements, release management, software engineer"
        />
        <meta property="og:url" content="https://www.failflow.com/hnrd" />
        <meta property="og:title" content="Hacker News Release Dashboard" />
        <meta
          property="og:description"
          content="Release, track and measure a Hacker News post or release"
        />
        <meta property="og:image" content="https://i.imgur.com/nWR8btF.png" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/nWR8btF.png"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/nWR8btF.png"
        />
      </Helmet>
    );
  }

  logout() {
    this.props.logOut();
  }

  decodeHTML(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  renderTopCommentsWithoutResponses() {
    const { allTopLevelComments, loading } = this.state;
    if (loading) {
      return (
        <div className={styles.commentsParent}>
          <div className={styles.subHeader}>
            Top-level Comments Without a Response
          </div>
          <div className={styles.commentRow}>
            <div>
              <div className={styles.emptyText}>Getting comments ...</div>
              <div className={styles.loadingHolder}>
                <div className={styles.skChase}>
                  <div class={styles.skChaseDot}></div>
                  <div class={styles.skChaseDot}></div>
                  <div class={styles.skChaseDot}></div>
                  <div class={styles.skChaseDot}></div>
                  <div class={styles.skChaseDot}></div>
                  <div class={styles.skChaseDot}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className={styles.commentsParent}>
        <div className={styles.subHeader}>
          Top-level Comments Without a Response
        </div>

        {allTopLevelComments.map((comment) => (
          <div className={styles.commentRow} key={comment.id}>
            <div className={styles.author}>
              <a
                href={`https://news.ycombinator.com/item?id=${comment.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                by: {comment.by}
              </a>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: this.decodeHTML(comment.text),
              }}
            />
          </div>
        ))}
      </div>
    );
  }

  renderPost() {
    const { hnPost, hnItem } = this.state;

    return (
      <div>
        <div className={styles.postTitle}>
          Post: <a href={hnItem.item ? hnItem.item.link : ""}>{hnPost.title}</a>
        </div>
        <div className={styles.statsRow}>
          <div className={styles.statsContainer}>
            <div>
              <div>Points</div>
              <div className={styles.metric}>{hnPost.score}</div>
            </div>
          </div>
          <div className={styles.statsContainer}>
            <div>
              <div>All Comments</div>
              <div className={styles.metric}>{hnPost.descendants}</div>
            </div>
          </div>
          <div className={styles.statsContainer}>
            <div>
              <div>Top-level Comments</div>
              <div className={styles.metric}>
                {hnPost.kids ? hnPost.kids.length : null}
              </div>
            </div>
          </div>
          <div className={styles.statsContainer}>
            <div>
              <div>Without Response</div>
              <div className={styles.metric}>
                {this.state.allTopLevelComments
                  ? this.state.allTopLevelComments.length
                  : null}
              </div>
            </div>
          </div>
        </div>
        {this.renderTopCommentsWithoutResponses()}
      </div>
    );
  }

  renderPostInfo() {
    return (
      <div className={styles.contentContainer}>
        <div className={styles.header}>HN Release Dashboard</div>
        {this.state.hnPost ? this.renderPost() : null}
      </div>
    );
  }

  render() {
    return (
      <>
        {this.renderHelmet()}
        <HNNavBar />

        <div className={styles.all}>
          <div className={styles.fullContainer}>{this.renderPostInfo()}</div>
          <Footer></Footer>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HNTrackContainer)
);
