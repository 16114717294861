import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "./HNList.module.scss";
import { bindActionCreators } from "redux";
import { logOut } from "../../actions/user";
import { connect } from "react-redux";
import { postWrapper } from "../../helpers/fetch";
import { FaTrash } from "react-icons/fa";

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ logOut }, dispatch);

class HNListItem extends Component {
  constructor() {
    super();
    this.deleteTracker = this.deleteTracker.bind(this);
  }

  goToEl(el) {
    const { history } = this.props;
    history.push(`/hnitem/${el._id}`);
  }

  async deleteTracker() {
    const { user, el } = this.props;

    await postWrapper(
      "POST",
      "/api/deleteTracker",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({ id: el._id })
    );
    this.props.removePostFromList(el);
  }

  render() {
    return (
      <div
        onClick={() => this.goToEl(this.props.el)}
        className={styles.trackerRow}
      >
        <div className={styles[`rowIcon${this.props.ind % 5}`]}></div>
        <div className={styles.postTitle}>{this.props.el.title}</div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            this.deleteTracker();
          }}
          className={styles.trashHolder}
        >
          <FaTrash className={styles.trashIcon} />
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HNListItem)
);
