import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "./SomethingNew.module.scss";
import { Helmet } from "react-helmet";
import Footer from "../home/Footer.jsx";
import NavBar from "../home/NavBar";
import { MdLoop } from "react-icons/md";
import { TwitterTweetEmbed } from "react-twitter-embed";

class SomethingNew extends Component {
  constructor() {
    super();
    this.state = {
      founder: {
        twitter: "loading",
        name: "Loading ...",
        bio: "Grabbing the bio right now, give us 30 milliseconds! ",
        proflink: "https://i.imgur.com/5p78yOv.jpg",
      },
    };
  }

  componentDidMount() {
    fetch(`/api/getOneSomethingNew`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ founder: data.item[0] });
      });
  }

  getNewProfile() {
    fetch(`/api/getOneSomethingNew`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ founder: data.item[0] });
      });
  }

  renderHelmet() {
    return (
      <Helmet>
        <title>Who's Building "Something New" | FailFlow</title>
        <meta
          name="description"
          content="Find out who is building something new.  Scraped Twitter profiles of accomplished people who will become the next batch of unicorns."
        />
        <meta
          name="keywords"
          content="building something new, twitter scraper, spreadsheet for building something new, twitter startups"
        />
        <meta
          property="og:url"
          content="https://www.failflow.com/somethingnew"
        />
        <meta
          property="og:title"
          content="Who's Building Something New | FailFlow"
        />
        <meta
          property="og:description"
          content="Find out who is building something new.  Scraped Twitter profiles of accomplished people who will become the next batch of unicorns."
        />
        <meta property="og:image" content="https://i.imgur.com/nWR8btF.png" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/Or4wssq.jpeg"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/Or4wssq.jpeg"
        />
      </Helmet>
    );
  }

  renderFounder() {
    const { founder } = this.state;

    return (
      <div className={styles.founderContainer}>
        <a
          href={`https://twitter.com/${founder.twitter}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles.whiteRing}>
            <div className={styles.founderImageContainer}>
              <img
                className={styles.founderImage}
                src={founder.proflink}
                alt="founder profile"
              ></img>
            </div>
          </div>
        </a>

        <div className={styles.founderNameAndHandle}>
          <div className={styles.founderName}>{founder.name}</div>
          <a
            href={`https://twitter.com/${founder.twitter}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={styles.founderHandle}>@{founder.twitter}</div>
          </a>
        </div>

        <div className={styles.founderBio}>{founder.bio}</div>
      </div>
    );
  }

  render() {
    return (
      <div className={styles.all}>
        {this.renderHelmet()}
        <NavBar></NavBar>
        <div className={styles.splashContainer}>
          <div className={styles.splashHeadingContainer}>
            <div className={styles.splashHeader}>
              Who's building something new?
            </div>
            <div className={styles.splashSubHeader}>
              Twitter profiles of the next unicorn founders
            </div>
            <div className={styles.splashSubHeaderStale}>
              STALE Data - Not Maintained!{" "}
            </div>
          </div>
          <div className={styles.seeAnotherContainer}>
            <div
              className={styles.seeAnother}
              onClick={() => this.getNewProfile()}
            >
              <div className={styles.reloadIcon}>
                <MdLoop></MdLoop>
              </div>
              Get Random Profile
            </div>
          </div>
          {this.renderFounder()}
        </div>
        <div className={styles.buySection}>
          <div className={styles.buyHeader}>Buy CSV + Request Dashboard</div>
          <div className={styles.buyDescription}>
            The preview above cycles through random available profiles. Buy the
            filterable ~80 row CSV below. If you're a VC or angel interested in
            a live dashboard that automatically scrapes Twitter and pushes
            notifications,{" "}
            <a href="mailto:shah@failflow.com">
              reach out to us here — shah@failflow.com .
            </a>
          </div>
          <div className={styles.spreadContainer}>
            <img
              className={styles.speadsheetImage}
              src="https://i.imgur.com/Zd5eayK.png"
              alt="spreadsheet"
            ></img>
          </div>
          <a
            href="https://gum.co/fXuKE"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={styles.buyButton}>Buy Dataset</div>
          </a>
        </div>
        <div className={styles.manifestoSection}>
          <div className={styles.buyHeader}>Why "Something New?"</div>
          <div className={styles.buyDescription}>
            On Twitter, it's become common to add "Building something new" in
            your bio to indicate that you're in the process of starting a new
            company. In addition, this can be a signal to VCs that you're
            pre-seed/seed and would consider raising money. To make life easier,
            we've scraped Twitter and aggregated this data so it's easily
            consumable.
          </div>
          <div className={styles.tweetMetaContainer}>
            <div className={styles.tweetContainer}>
              <div>
                <TwitterTweetEmbed tweetId={"1395533877846822912"} />
              </div>
              <div>
                <TwitterTweetEmbed tweetId={"1385329203223031809"} />
              </div>
              <div>
                <TwitterTweetEmbed tweetId={"1402061379167653908"} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.whoAreWeSection}>
          <div className={styles.whoAreWeHeader}>
            Who Are We? (The Royal We)
          </div>
          <div className={styles.whoAreWeDesc}>
            Hi! I'm Shah. The fun projects I build (like the one you're looking
            at) go up on this site. I'm the Head of Product at Kapwing and a
            builder at heart. I live in NYC and love meeting new people! Check
            out <a href="https://www.galeeb.com">my other projects</a> and
            <a href={`https://twitter.com/shahgaleeb`}>
              {" "}
              follow me on Twitter!
            </a>
          </div>
          <div className={styles.founderContainer}>
            <a
              href={`https://twitter.com/shahgaleeb`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={styles.whiteRing}>
                <div className={styles.founderImageContainer}>
                  <img
                    className={styles.founderImage}
                    src={
                      "https://pbs.twimg.com/profile_images/1488601018489257993/-bAujKrK_400x400.jpg"
                    }
                    alt="founder profile"
                  ></img>
                </div>
              </div>
            </a>

            <div className={styles.founderNameAndHandle}>
              <div className={styles.founderName}>Shah Ahmed</div>
              <a
                href={`https://twitter.com/shahgaleeb`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.founderHandle}>@shahgaleeb</div>
              </a>
            </div>

            <div className={styles.founderBio}>
              head of product @kapwingapp | @cornell , fun projects at
              galeeb.com
            </div>
          </div>
          <a href="https://www.galeeb.com">
            <div className={styles.otherProjects}>See Other Projects</div>
          </a>
        </div>

        <Footer></Footer>
      </div>
    );
  }
}

export default withRouter(SomethingNew);
