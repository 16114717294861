import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "./ExpertForm.module.scss";
import { Helmet } from "react-helmet";
import Footer from "../home/Footer.jsx";
import { bindActionCreators } from "redux";
import { logOut } from "../../actions/user";
import { setExpert } from "../../actions/expert";
import { connect } from "react-redux";
import { getWrapper, postWrapper } from "../../helpers/fetch";
import stripePower from "./stripe.svg";

const mapStateToProps = (state) => ({
  user: state.user,
  fullState: state,
  expert: state.expert,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setExpert, logOut }, dispatch);

class ExportForm extends Component {
  constructor() {
    super();
    this.state = {
      pageNum: 0,
      siteName: "",
      yourName: "",
      yourBio: "",
      theVerb: "consult",
      helpList: "",
      rate: "",
      invalidPageName: false,
    };
  }

  componentDidMount() {
    const { user, history, expert } = this.props;
    console.log(this.props.fullState);
    if (Object.entries(user).length === 0) {
      history.push("/expert");
    } else if (Object.entries(expert).length > 0) {
      console.log(expert);
      history.push("/expert/dashboard");
    }
  }

  updateSiteInfo(e, key) {
    this.setState({ [key]: e.target.value });
  }

  selectVerb(verb) {
    this.setState({ theVerb: verb });
  }

  logout() {
    this.props.logOut();
    this.props.history.push("/expert");
  }

  getMultiSelectClasses(verb) {
    const { theVerb } = this.state;
    if (theVerb === verb) {
      return `${styles.liPretty} ${styles.selectedVerb}`;
    }
    return styles.liPretty;
  }

  async createExpertEntry() {
    const { user, setExpert } = this.props;

    const { siteName, yourName, yourBio, theVerb, helpList, rate } = this.state;
    const response = await postWrapper(
      "POST",
      "/api/addExpert",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({ siteName, yourName, yourBio, theVerb, helpList, rate })
    );
    console.log(response);
    setExpert(response.expert);
  }

  async createStripeAccount() {
    const { user } = this.props;
    const response = await postWrapper(
      "POST",
      "/api/createStripeAccount",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({ poop: "poop value" })
    );
    console.log(response);
  }

  async createStripeAccountLink() {
    const { user } = this.props;
    const { expert } = this.props;
    const { stripeAccountId } = expert;
    const response = await postWrapper(
      "POST",
      "/api/createAccountLink",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({ stripeAccountId, expert })
    );
    console.log(response);
    window.location = response.accountLinks.url;
  }

  async checkSiteName() {
    const { user } = this.props;
    const response = await getWrapper(
      "GET",
      `/api/expert/checkSiteName/${this.state.siteName}`,
      {
        Authorization: user.token,
      }
    );
    return response.alreadyExists;
  }

  async finishPage1() {
    const alreadyExists = await this.checkSiteName();
    if (alreadyExists) {
      this.setState({ invalidPageName: true });
    } else {
      this.createExpertEntry();
      this.setState({ pageNum: 1 });
    }
  }

  cleanUpSiteName() {
    // remove spaces and special characters from siteName
    // TODO also do this on the backend
    let { siteName } = this.state;
    let cleanSiteName = siteName.replace(/[^a-zA-Z ]/g, "");
    cleanSiteName = cleanSiteName.replace(/\s/g, "");
    this.setState({ siteName: cleanSiteName });
  }

  removeErrors() {
    this.setState({ invalidPageName: false });
  }

  renderFormPage2() {
    return (
      <div>
        <div className={styles.paymentsHeader}>Enabling Payments</div>
        <div className={styles.paymentsSubHeader}>
          In order to accept payments, you will need to set up an account with
          Stripe.
        </div>
        <div>
          You will need to provide some identifying information to Stripe, you
          can set up an account later.
        </div>
        <div>
          <div
            className={styles.stripeButton}
            onClick={() => this.createStripeAccountLink()}
          >
            Set Up Stripe Account
          </div>
          <div className={styles.stripeBadge}>
            <img width="150px" src={stripePower} alt="powered by stripe"></img>
          </div>
        </div>
        <div
          className={styles.continue}
          onClick={() => this.props.history.push("/expert/dashboard")}
        >
          Skip
        </div>
      </div>
    );
  }

  renderFormPage1() {
    const {
      siteName,
      yourName,
      yourBio,
      helpList,
      rate,
      invalidPageName,
    } = this.state;

    return (
      <>
        <div className={styles.pageHeader}>Let's get started</div>
        <div className={styles.questionContainer}>
          <div className={styles.questionLabel}>Page Name</div>
          <div className={styles.questionDetails}>
            Sets up your site: failflow.com/ex/siteName. No special characters
            or spaces.
          </div>
          {invalidPageName ? (
            <div className={styles.invalid}>
              This site is unavailable, please try another
            </div>
          ) : null}

          <input
            className={styles.simpleInput}
            placeholder="Site Name"
            value={siteName}
            maxLength="50"
            onFocus={() => this.removeErrors()}
            onBlur={() => this.cleanUpSiteName()}
            onChange={(e) => this.updateSiteInfo(e, "siteName")}
          ></input>
        </div>
        <div className={styles.questionContainer}>
          <div className={styles.questionLabel}>Your Name</div>
          <div className={styles.questionDetails}>
            Displays your name in the introduction "Hi, I'm Shah!" Feel free to
            use you first name, first and last, nickname, or a handle
          </div>
          <input
            className={styles.simpleInput}
            placeholder="Name/Handle"
            value={yourName}
            maxLength="100"
            onChange={(e) => this.updateSiteInfo(e, "yourName")}
          ></input>
        </div>
        <div className={styles.questionContainer}>
          <div className={styles.questionLabel}>Quick Bio</div>
          <div className={styles.questionDetails}>
            Share a little bit about yourself - who you are, what you do, what
            you like! Up to 300 characters.
          </div>
          <textarea
            className={styles.simpleArea}
            placeholder="I'm a product thinker and creator. I'm the first Product Manager at Kapwing - an online video editor. I also create my own projects at FailFlow. I love building and I love helping people!"
            value={yourBio}
            maxLength="300"
            onChange={(e) => this.updateSiteInfo(e, "yourBio")}
          ></textarea>
        </div>

        <div className={styles.questionContainer}>
          <div className={styles.questionLabel}>How You'll Help</div>
          <div className={styles.questionDetails}>
            How will you help people? Will you consult, work on projects,
            mentor, tutor? This is used to update some of the instructional text
            on your page
          </div>
          <div className={styles.multiChoiceContainer}>
            <ul className={styles.ulPretty}>
              <li
                className={this.getMultiSelectClasses("Consult")}
                onClick={() => this.selectVerb("Consult")}
              >
                Consult
              </li>
              <li
                className={this.getMultiSelectClasses("Mentor")}
                onClick={() => this.selectVerb("Mentor")}
              >
                Mentor
              </li>
              <li
                className={this.getMultiSelectClasses("Tutor")}
                onClick={() => this.selectVerb("Tutor")}
              >
                Tutor
              </li>
              <li
                className={this.getMultiSelectClasses("Work")}
                onClick={() => this.selectVerb("Work")}
              >
                Work
              </li>
            </ul>{" "}
          </div>
        </div>

        <div className={styles.questionContainer}>
          <div className={styles.questionLabel}>What You Can Help With</div>
          <div className={styles.questionDetails}>
            A list of things you can help with: ex: "web design, SaaS product
            management and Super Smash Bros. tips"
          </div>
          <input
            className={`${styles.simpleInput} ${styles.simpleInputTight}`}
            placeholder="IT planning, SaaS unit economics and time management"
            value={helpList}
            onChange={(e) => this.updateSiteInfo(e, "helpList")}
          ></input>
        </div>
        <div className={styles.questionContainer}>
          <div className={styles.questionLabel}>Rate</div>
          <div className={styles.questionDetails}>
            How much will you charge each hour? USD
          </div>
          <input
            type="number"
            className={`${styles.simpleInput} ${styles.rate}`}
            placeholder="20.00"
            value={rate}
            onChange={(e) => this.updateSiteInfo(e, "rate")}
          ></input>
        </div>
        <div
          className={styles.bottomContainer}
          onClick={() => this.finishPage1()}
        >
          <div className={styles.continue}>Continue</div>
        </div>
      </>
    );
  }

  renderHelmet() {
    return (
      <Helmet>
        <title>Hacker News Release Dashboard</title>
        <meta
          name="description"
          content="Release, track and measure a Hacker News post or release"
        />
        <meta
          name="keywords"
          content="Hacker news, comements, release management, software engineer"
        />
        <meta property="og:url" content="https://www.failflow.com/hnrd" />
        <meta property="og:title" content="Hacker News Release Dashboard" />
        <meta
          property="og:description"
          content="Release, track and measure a Hacker News post or release"
        />
        <meta property="og:image" content="https://i.imgur.com/nWR8btF.png" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/nWR8btF.png"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/nWR8btF.png"
        />
      </Helmet>
    );
  }

  getNumberStyle(num) {
    const { pageNum } = this.state;
    if (num - 1 === pageNum) {
      return styles.currentNumber;
    } else if (num - 1 < pageNum) {
      return styles.currentNumber;
    } else {
      return styles.upcomingNumber;
    }
  }

  renderProgress() {
    return (
      <div className={styles.progress}>
        <div className={styles.progressItem}>
          <div className={this.getNumberStyle(1)}>1</div>
          <div className={styles.stepName}>Your Page</div>
        </div>
        <div className={styles.spacer}></div>
        <div className={styles.progressItem}>
          <div className={this.getNumberStyle(2)}>2</div>
          <div className={styles.stepName}>Payments</div>
        </div>
        <div className={styles.spacer}></div>
        <div className={styles.progressItem}>
          <div className={this.getNumberStyle(3)}>3</div>
          <div className={styles.stepName}>Dashboard</div>
        </div>
      </div>
    );
  }

  render() {
    const { pageNum } = this.state;

    return (
      <div className={styles.all}>
        {this.renderHelmet()}
        <div className={styles.headerStrip}>{this.renderProgress()}</div>
        <div className={styles.containerBorder}>
          <div className={styles.contentContainer}>
            {pageNum === 0 ? this.renderFormPage1() : null}
            {pageNum === 1 ? this.renderFormPage2() : null}

            <div className={styles.exit} onClick={() => this.props.logOut()}>
              Exit
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ExportForm)
);
