import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showToast } from "../../actions/toasts";
import styles from "./Home.module.scss";
import Footer from "./Footer.jsx";
import { Helmet } from "react-helmet";
import Hero from './Hero'

const mapStateToProps = (state) => ({
  fullState: state,
  todos: state.todos,
  user: state.user,
  showToast: state.toast.show,
  toastMessage: state.toast.message,
});

const mapDispatchToProps = (dispatch) =>
  // okay we need to put the token in the state
  // so we can send it to authorized responses
  bindActionCreators({ showToast }, dispatch);

class Home extends Component {

  constructor() {
    super();
    this.state = {

    };
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {
    // if (
    //   prevProps.user !== this.props.user
    // ) {
    //   this.props.history.push('/dashboard')
    // }
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>FailFlow - You're going to die.  Make the most of it.</title>
          <meta
            name="description"
            content="Daily activities and reminders to be more mindful.  Create a private gratitude journal and use mortality reminders."
          />
          <meta
            name="keywords"
            content="failflow, weeks until you die, gratitude journal"
          />
          <meta property="og:url" content="https://www.failflow.com" />
          <meta property="og:title" content="FailFlow" />
          <meta
            property="og:description"
            content="Daily activities and reminders to be more mindful.  Create a private gratitude journal and use mortality reminders."
          />
          <meta property="og:image" content="https://i.imgur.com/1epBOho.png" />
          <meta
            property="og:image:url"
            content="https://i.imgur.com/1epBOho.png"
          />
          <meta
            property="twitter:image"
            content="https://i.imgur.com/1epBOho.png"
          />
        </Helmet>
        <div className={styles.main}>
          <Hero></Hero>
          {/* second part of landing */}
          <div className="relative overflow-hidden bg-zinc-900 pt-16 pb-32" id="product">
            <div className="relative">
              <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
                <div className="mx-auto max-w-xl px-4 sm:px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0">
                  <div>
                    <div>

                    </div>
                    <div className="mt-6">
                      <h2 className="text-3xl font-bold tracking-tight text-white">Being Grateful Leads to Being Happy</h2>
                      <p className="mt-4 text-lg text-gray-300">
                        Practice daily gratitude with your own personal gratitude journal.  Get daily reminders to write in your journal and keep the habit to reap the benefits.
                      </p>

                      <div className="mt-6 mb-14">
                        <a
                          href="#"
                          className="inline-flex rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700"
                        >
                          Start Your Journal
                        </a>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="mt-12 sm:mt-16 lg:mt-0">
                  <div className="-mr-48 pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                    <img
                      className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                      src="https://i.imgur.com/uMbPZJU.png"
                      alt="Inbox user interface"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-24">
              <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
                <div className="mx-auto max-w-xl px-4 sm:px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:py-32 lg:px-0">
                  <div>
                    <div>

                    </div>
                    <div className="mt-6">
                      <h2 className="text-3xl font-bold tracking-tight text-white">Feel Better With Each Day</h2>
                      <p className="mt-4 text-lg text-gray-300">
                        Studies show continuous practice can result in higher energy levels and a boosted mood with 14 days of regular reflection.  Combine this with our mortality reflection for extra impact.  Use our streaks calendar to get on track.
                      </p>
                      <div className="mt-6">
                        <a
                          href="#"
                          className="inline-flex rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700"
                        >
                          Get started
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
                  <div className="-ml-48 pr-4 sm:pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                    <img
                      className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                      src="https://i.imgur.com/IYAFoqT.png"
                      alt="Customer profile user interface"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="relative overflow-hidden pb-32 bg-white pt-16 sm:pt-24 lg:pt-32">
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
              <div>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Successful People Reflect Regularly</p>
                <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
                  You'll be in good company.  Many successful creators and entrepreneurs regularly reflect on their mortality and practice gratitude.
                </p>
              </div>
              <div className="mt-12 justify-between md:flex">
                <a href="https://twitter.com/awilkinson/status/1183497112870445057" target="_blank" rel="noreferrer">
                  <img className="max-h-[450px] mb-5 md:mb-0" src="https://i.imgur.com/keAZM44.png" alt='andrew tweet'></img>
                </a>
                <a href="https://twitter.com/BrentO/status/1183725391720132608" target="_blank" rel="noreferrer">
                  <img className="max-h-[450px] mb-5 md:mb-0" src="https://i.imgur.com/6y7ueZA.png" alt='andrew tweet'></img>
                </a>
                <a href="https://twitter.com/ichiipsy/status/1215416647130869761" target="_blank" rel="noreferrer">
                  <img className="max-h-[450px] mb-5 md:mb-0" src="https://i.imgur.com/4QQSs6W.png" alt='andrew tweet'></img>
                </a>

              </div>
              <div>

              </div>
            </div>
          </div>
          <div className="bg-blue-700">
            <div className="mx-auto max-w-2xl py-16 px-4 text-center sm:py-20 sm:px-6 lg:px-8">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                <span className="block">Start living more.</span>
                <span className="block">Before you die.</span>
              </h2>
              <p className="mt-4 text-lg leading-6 text-indigo-200">
                Sign up for free before we launch later this year.</p>
              <a
                href="/signup"
                className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-blue-600 hover:bg-blue-50 sm:w-auto"
              >
                Sign up for free
              </a>
            </div>
          </div>

        </div>

        <Footer />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
