import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "./HumanSounds.module.scss";
import HumanNavBar from "./HumanNavBar.jsx";
import { Helmet } from "react-helmet";
import Footer from "../home/Footer.jsx";
import { bindActionCreators } from "redux";
import { setUser } from "../../actions/user";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  user: state.user,
  fullState: state,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setUser }, dispatch);

class HumanSounds extends Component {
  goToHumanPay() {
    const { history } = this.props;
    history.push("/humansounds/pay");
  }

  renderHelmet() {
    return (
      <Helmet>
        <title>Human Sounds</title>
        <meta
          name="description"
          content="UX sound library for humans by humans"
        />
        <meta
          name="keywords"
          content="UX sounds, human sounds, audio list, human audio, human audio library"
        />
        <meta
          property="og:url"
          content="https://www.failflow.com/humansounds"
        />
        <meta property="og:title" content="Human Sounds" />
        <meta
          property="og:description"
          content="UX sound library for humans by humans"
        />
        <meta property="og:image" content="https://i.imgur.com/8GBW24B.png" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/8GBW24B.png"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/8GBW24B.png"
        />
      </Helmet>
    );
  }

  renderHumanSounds() {
    return (
      <div className={styles.fullContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.splashHeader}>Human Sounds</div>
          <div className={styles.splashDescription}>
            Humanize your UX with sounds that your users can relate to
          </div>

          <div className={styles.splashImageContainer}>
            <img
              alt="man listening to music"
              className={styles.splashImage}
              src="https://i.imgur.com/IrTVEV6.png"
            ></img>
          </div>
          <div className={styles.holdProductHunt}>
            <a
              href="https://www.producthunt.com/posts/human-ux-sounds?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-human-ux-sounds"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=209610&theme=light"
                alt="Human UX Sounds - Humanize your UX with sounds that your users can relate to | Product Hunt Embed"
                style={{ width: "250px", height: "54px" }}
                width="250px"
                height="54px"
              />
            </a>
          </div>
          <div>
            <div className={styles.explainerText}>
              Listen to the sounds below and check out how they were made. All
              sounds were made by a human and for a human! You can download the
              videos for free or purchase the audio library.{" "}
            </div>
            <div
              onClick={() => this.goToHumanPay()}
              className={styles.getItButton}
            >
              Download Audio Library
            </div>
          </div>

          <div className={styles.allSounds}>
            <div className={styles.soundContainer}>
              <div className={styles.soundName}>Wind Down</div>
              <div className={styles.goodFor}>
                <span className={styles.bold}>Good for: </span>Closing
                application, Shutting down OS, Logging out{" "}
              </div>
              <div className={styles.goodFor}>
                <span className={styles.bold}>Vibe: </span> Melodic, Floaty{" "}
              </div>
              <div >
                <audio className="mx-auto" controls src="https://i.imgur.com/3eYr5X3.mp4"></audio>{" "}
                <a
                  rel="noopener noreferrer"
                  href="https://imgur.com/a/g5sybDK"
                  target="_blank"
                >
                  <div className={styles.seeHow}>See how it was made!</div>
                </a>
              </div>
            </div>
            <div className={styles.soundContainer}>
              <div className={styles.soundName}>Pop</div>
              <div className={styles.goodFor}>
                <span className={styles.bold}>Good for: </span>Push/App
                notifications, New message{" "}
              </div>
              <div className={styles.goodFor}>
                <span className={styles.bold}>Vibe: </span> Light, Friendly{" "}
              </div>
              <div>
                <audio controls src="https://i.imgur.com/CetC6AZ.mp4"></audio>{" "}
                <a
                  rel="noopener noreferrer"
                  href="https://imgur.com/a/T3kl18K"
                  target="_blank"
                >
                  <div className={styles.seeHow}>See how it was made!</div>
                </a>
              </div>
            </div>
            <div className={styles.soundContainer}>
              <div className={styles.soundName}>Tick</div>
              <div className={styles.goodFor}>
                <span className={styles.bold}>Good for: </span>
                Increment/Decrement, Clock, Time Elements{" "}
              </div>
              <div className={styles.goodFor}>
                <span className={styles.bold}>Vibe: </span> Urgent, Reverby{" "}
              </div>
              <div>
                <audio className="mx-auto" controls src="https://i.imgur.com/FOwSbHh.mp4"></audio>{" "}
                <a
                  rel="noopener noreferrer"
                  href="https://imgur.com/a/zqmNYQ0"
                  target="_blank"
                >
                  <div className={styles.seeHow}>See how it was made!</div>
                </a>
              </div>
            </div>
            <div className={styles.soundContainer}>
              <div className={styles.soundName}>Nudge</div>
              <div className={styles.goodFor}>
                <span className={styles.bold}>Good for: </span>Warning, Invalid
                State{" "}
              </div>
              <div className={styles.goodFor}>
                <span className={styles.bold}>Vibe: </span> Bassy, Naggy{" "}
              </div>
              <div>
                <audio controls className="mx-auto" src="https://i.imgur.com/oE2pZCZ.mp4"></audio>{" "}
                <a
                  rel="noopener noreferrer"
                  href="https://imgur.com/a/9kIyRZd"
                  target="_blank"
                >
                  <div className={styles.seeHow}>See how it was made!</div>
                </a>
              </div>
            </div>
            <div className={styles.soundContainer}>
              <div className={styles.soundName}>Tweet</div>
              <div className={styles.goodFor}>
                <span className={styles.bold}>Good for: </span>Informational
                Toast, Notification{" "}
              </div>
              <div className={styles.goodFor}>
                <span className={styles.bold}>Vibe: </span> Bright, Friendly{" "}
              </div>
              <div>
                <audio controls src="https://i.imgur.com/YZSLckV.mp4"></audio>{" "}
                <a
                  rel="noopener noreferrer"
                  href="https://imgur.com/a/zw8Z1tt"
                  target="_blank"
                >
                  <div className={styles.seeHow}>See how it was made!</div>
                </a>
              </div>
            </div>
            <div className={styles.soundContainer}>
              <div className={styles.soundName}>Deflate - High</div>
              <div className={styles.goodFor}>
                <span className={styles.bold}>Good for: </span>Invalid state,
                Error{" "}
              </div>
              <div className={styles.goodFor}>
                <span className={styles.bold}>Vibe: </span> Sad{" "}
              </div>
              <div>
                <audio className="mx-auto" controls src="https://i.imgur.com/LoHoUSw.mp4"></audio>{" "}
                <a
                  rel="noopener noreferrer"
                  href="https://imgur.com/a/P3ZV01H"
                  target="_blank"
                >
                  <div className={styles.seeHow}>See how it was made!</div>
                </a>
              </div>
            </div>
            <div className={styles.soundContainer}>
              <div className={styles.soundName}>Strike</div>
              <div className={styles.goodFor}>
                <span className={styles.bold}>Good for: </span>
                Increment/Decrement, High Fives, Animations{" "}
              </div>
              <div className={styles.goodFor}>
                <span className={styles.bold}>Vibe: </span> Physical, Fast{" "}
              </div>
              <div>
                <audio className="mx-auto" controls src="https://i.imgur.com/XRBnEqV.mp4"></audio>{" "}
                <a
                  rel="noopener noreferrer"
                  href="https://imgur.com/a/rcyinzi"
                  target="_blank"
                >
                  <div className={styles.seeHow}>See how it was made!</div>
                </a>
              </div>
            </div>
            <div className={styles.soundContainer}>
              <div className={styles.soundName}>Deflate - Low</div>
              <div className={styles.goodFor}>
                <span className={styles.bold}>Good for: </span>Invalid state,
                Error{" "}
              </div>
              <div className={styles.goodFor}>
                <span className={styles.bold}>Vibe: </span> Sad{" "}
              </div>

              <div>
                <audio className="mx-auto" controls src="https://i.imgur.com/Zo3SDZ5.mp4"></audio>{" "}
                <a
                  rel="noopener noreferrer"
                  href="https://imgur.com/a/g9iI14K"
                  target="_blank"
                >
                  <div className={styles.seeHow}>See how it was made!</div>
                </a>
              </div>
            </div>
            <div className={styles.soundContainer}>
              <div className={styles.soundName}>Pishoo</div>
              <div className={styles.goodFor}>
                <span className={styles.bold}>Good for: </span>Send
                message/mail, Lift off, Log off{" "}
              </div>
              <div className={styles.goodFor}>
                <span className={styles.bold}>Vibe: </span> Airy, Urgent{" "}
              </div>
              <div>
                <audio className="mx-auto" controls src="https://i.imgur.com/Vr3afoB.mp4"></audio>{" "}
                <a
                  rel="noopener noreferrer"
                  href="https://imgur.com/a/rEIBxQR"
                  target="_blank"
                >
                  <div className={styles.seeHow}>See how it was made!</div>
                </a>
              </div>
            </div>
            <div className={styles.soundContainer}>
              <div className={styles.soundName}>"Wake Up!"</div>
              <div className={styles.goodFor}>
                <span className={styles.bold}>Good for: </span>Alarm, Scaring
                People{" "}
              </div>
              <div className={styles.goodFor}>
                <span className={styles.bold}>Vibe: </span> Urgent, Demanding{" "}
              </div>
              <div>
                <audio className="mx-auto" controls src="https://i.imgur.com/IYpA1cQ.mp4"></audio>{" "}
                <a
                  rel="noopener noreferrer"
                  href="https://imgur.com/a/Uqiz6DA"
                  target="_blank"
                >
                  <div className={styles.seeHow}>See how it was made!</div>
                </a>
              </div>
            </div>
            <div>
              <div
                onClick={() => this.goToHumanPay()}
                className={styles.getItButton}
              >
                Download Audio Library
              </div>
            </div>
            <div className={styles.bar}></div>
            <div>
              <div className={styles.sectionHeader}>FAQs</div>
              <div className={styles.question}>Why make this?</div>
              <div className={styles.answerText}>
                Most UX sounds are synthesized and sound like they were made for
                machines, or often poorly imitate humans. Think "You've got
                mail." But humans respond to natural sounds meant for humans. So
                we've created an audio library just for that!
              </div>
              <div className={styles.question}>But, really?</div>
              <div className={styles.answerText}>
                Okay, fine. It was a little tongue-in-cheek and I thought it was
                a fun project. But the sounds actually turned out really well
                and they can be used in applications.
              </div>
              <div className={styles.question}>
                What are you going to do with my money?
              </div>
              <div className={styles.answerText}>
                All sales will be donated to the{" "}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://eji.org/"
                >
                  Equal Justice Initiative
                </a>{" "}
                and I'll also match the donations up to $1,000 in total sales.
              </div>
              <div className={styles.question}>
                Where did you get the illustrations on this page?
              </div>
              <div className={styles.answerText}>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://icons8.com/"
                >
                  icons8
                </a>
              </div>
              <div className={styles.question}>Will you make more?</div>
              <div className={styles.answerText}>
                I'd love to! It was a lot of fun. Only if there is interest
                though.
              </div>
            </div>
            <div className={styles.endImageContainer}>
              <img
                alt="moon landing!"
                className={styles.endImage}
                src="https://i.imgur.com/ku2DiEx.png"
              ></img>
            </div>
            <div>
              <a href="/">
                <div className={styles.getItButton}>See other projects</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={styles.all}>
        {this.renderHelmet()}
        <HumanNavBar />
        {this.renderHumanSounds()}
        <Footer></Footer>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HumanSounds)
);
