import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import HNNavBar from "./HNNavBar";
import styles from "./HNPayForm.module.scss";
import { Helmet } from "react-helmet";
import { Elements } from "react-stripe-elements";
import HNPayForm from "./HNPayForm";

class Pay extends Component {
  renderHelmet() {
    return (
      <Helmet>
        <title>Hacker News Release Dashboard</title>
        <meta
          name="description"
          content="Release, track and measure a Hacker News post or release"
        />
        <meta
          name="keywords"
          content="Hacker news, comements, release management, software engineer"
        />
        <meta property="og:url" content="https://www.failflow.com/hntrack" />
        <meta property="og:title" content="Hacker News Release Dashboard" />
        <meta
          property="og:description"
          content="Release, track and measure a Hacker News post or release"
        />
        <meta property="og:image" content="https://i.imgur.com/nWR8btF.png" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/nWR8btF.png"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/nWR8btF.png"
        />
      </Helmet>
    );
  }

  render() {
    return (
      <div>
        {this.renderHelmet()}
        <HNNavBar />
        <div className={styles.fullContainer}>
          <div className={styles.paymentContainer}>
            <div className={styles.header}>Upgrade to Pro</div>
            <div className={styles.subHeader}>
              You've selected the <span className={styles.highlight}>Pro</span>{" "}
              plan to be billed monthly at{" "}
              <span className={styles.highlight}>$15</span>
            </div>
            <div className={styles.lineItemContainer}>
              <div className={styles.how}>
                <div className={styles.howHeader}>Whats included in Pro</div>
                <div>
                  <div className={styles.lineItem}>Unlimited trackers</div>
                  <div className={styles.lineItem}>Premium support</div>
                  <div className={styles.lineItem}>*Private trackers</div>
                  <div className={styles.lineItem}>
                    *Time series analysis of votes/comments
                  </div>
                  <div className={styles.lineItem}>
                    *Tracking on lower level comments
                  </div>
                </div>
                <div className={styles.total}>Total: $15 due now </div>
                <div className={styles.helperText}>
                  *denotes the functionality is not available and is scheduled
                  for the June 30th release{" "}
                </div>
              </div>
            </div>
            <div>Enter your payment details:</div>
            <Elements>
              <HNPayForm />
            </Elements>
            <div className={styles.disclaimer}>
              You can cancel at any time from your Account Overview
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Pay);
