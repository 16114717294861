import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "./DieInput.module.scss";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
// import Footer from "../home/Footer.jsx";
import ReactGA from "react-ga";
import {
  ChevronLeftIcon,
} from '@heroicons/react/20/solid'
// import { logEvent } from "../../helpers/amplitudeHelper";

const mapStateToProps = (state) => ({
  fullState: state,
  todos: state.todos,
  user: state.user,
  showToast: state.toast.show,
  toastMessage: state.toast.message,
});

class Die extends Component {
  constructor() {
    super();
    this.state = {
      month: 0,
      day: 1,
      year: 2005,
      birthday: null,
      poo: 4,
    };
  }

  componentDidMount() { }

  handleYearChange(e) {
    const { month, day, year } = this.state;
    this.setState({ year: e.target.value });
    this.setState({
      birthday: new Date(parseInt(year), parseInt(month), parseInt(day)),
    });
  }

  handleMonthChange(e) {
    const { month, day, year } = this.state;
    this.setState({ month: e.target.value });
    this.setState({
      birthday: new Date(parseInt(year), parseInt(month), parseInt(day)),
    });
  }

  handleDayChange(e) {
    const { month, day, year } = this.state;
    this.setState({ day: e.target.value });
    this.setState({
      birthday: new Date(parseInt(year), parseInt(month), parseInt(day)),
    });
  }

  useMybirthday() {
    this.setState(
      {
        year: 1992,
        month: 2,
        day: 5,
        birthday: new Date(parseInt(1992), parseInt(2), parseInt(5)),
      },
      () => this.setDate()
    );
  }

  setDate() {
    function weeksBetween(date1, date2) {
      var ONE_WEEK = 1000 * 60 * 60 * 24 * 7;
      var date1_ms = date1.getTime();
      var date2_ms = date2.getTime();
      var difference_ms = Math.abs(date1_ms - date2_ms);
      return Math.floor(difference_ms / ONE_WEEK);
    }

    const { month, day, year } = this.state;
    let birthday = new Date(year, month, day);

    fetch("/api/updateBirthday", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: this.props.user.token,
      },
      body: JSON.stringify({ month, day, year }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // this.props.setToken(data.token);
        // this.props.setUser(data);
      });

    const passed = weeksBetween(birthday, new Date());
    this.props.history.push("/mortality/" + passed);
    ReactGA.event({
      category: "Navigation",
      action: "Used Birthday Prompt",
    });
    // logEvent("Entered Birthday");
  }

  renderBirthdayEntry() {
    let years = [];
    for (let x = 1930; x <= 2005; x++) {
      years.unshift(x);
    }

    return (
      <div>
        <div className="bg-gray-800 items-center text-white font-semibold text-lg h-16 flex pl-10">
          <div>
            <a href="/dashboard">
              <div className="flex items-center hover:cursor-pointer hover:opacity-80">
                <ChevronLeftIcon className="h-6 text-white"></ChevronLeftIcon>
                <div>Back to Activities</div>
              </div>
            </a>
          </div>
        </div>
        <div className={styles.enterBirthday}>
          <div>Poop</div>
          <div className={styles.entryContent}>
            <div className={styles.entryHeader}>
              How many weeks until you die?
            </div>
            <div className={styles.entryHelpText}>Enter your birthday</div>
            {/* <Select options={options}></Select> */}
            <select
              className={styles.yearEnter}
              onChange={(e) => this.handleMonthChange(e)}
            >
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>`
            </select>
            <select
              className={styles.yearEnter}
              onChange={(e) => this.handleDayChange(e)}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
              <option value="24">24</option>
              <option value="25">25</option>
              <option value="26">26</option>
              <option value="27">27</option>
              <option value="28">28</option>
              <option value="29">29</option>
              <option value="30">30</option>
              <option value="31">31</option>
            </select>
            <select
              onChange={(e) => this.handleYearChange(e)}
              className={styles.yearEnter}
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>

            <div className={styles.enterButton} onClick={() => this.setDate()}>
              DONE
            </div>

          </div>
        </div></div>

    );
  }

  renderHelmet() {
    return (
      <Helmet>
        <title>How many weeks until you die?</title>
        <meta
          name="description"
          content="Find out how many more weeks you have to live"
        />
        <meta name="keywords" content="weeks die, inspiring, chart of life" />
        <meta property="og:url" content="https://www.failflow.com/die" />
        <meta property="og:title" content="How many weeks until you die?" />
        <meta
          property="og:description"
          content="Find out how many more weeks you have to live!"
        />
        <meta property="og:image" content="https://i.imgur.com/NuWGTgh.png" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/NuWGTgh.png"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/NuWGTgh.png"
        />
      </Helmet>
    );
  }

  render() {
    return (
      <div>
        {this.renderHelmet()}
        {this.renderBirthdayEntry()}
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, null)((Die)));

