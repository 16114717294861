import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { hideToast } from "./actions/toasts";
import { connect } from "react-redux";
import styles from "./Toast.module.scss";

const mapStateToProps = (state) => ({
  fullState: state,
  todos: state.todos,
  user: state.user,
  showToast: state.toast.show,
  toastMessage: state.toast.message,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ hideToast }, dispatch);

class Toast extends Component {
  componentDidUpdate(prevProps) {
    const { showToast } = this.props;

    if (showToast === true && prevProps.showToast === false) {
      this.dissapearToast();
    }
  }

  dissapearToast() {
    let x = document.getElementById("snackbar");
    if (x) {
      // After 3 seconds, remove toast
      setTimeout(() => {
        this.props.hideToast();
      }, 3000);
    }
  }

  render() {
    const { showToast, toastMessage } = this.props;
    return (
      <>
        {showToast ? (
          <div
            id="snackbar"
            className={styles.snackbar}
            onClick={() => this.props.hideToast()}
          >
            {toastMessage}
          </div>
        ) : null}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Toast);
