import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import NavBar from "../home/NavBar.jsx";
import styles from "./ThankYou.module.scss";
import { Helmet } from "react-helmet";

class ThankYou extends Component {
  renderHelmet() {
    return (
      <Helmet>
        <title>Thank You for getting THE PASS! </title>
        <meta
          name="description"
          content="Thank you for getting THE PASS.  Here is a note from the creator.  "
        />
        <meta property="og:url" content="https://www.failflow.com/thankyou" />
        <meta property="og:title" content="Thank You for getting THE PASS! " />
        <meta
          property="og:description"
          content="Thank you for getting THE PASS.  Here is a note from the creator.  "
        />
        <meta property="og:image" content="https://i.imgur.com/djWAckr.png" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/djWAckr.png"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/djWAckr.png"
        />
      </Helmet>
    );
  }

  render() {
    return (
      <div>
        {this.renderHelmet()}
        <NavBar />
        <div className={styles.thankYouContainer}>
          <div className={styles.header}>Thank You! You're all set</div>
          <div className={styles.subHeader}>
            You've got the <i>THE PASS</i> now. We'll email you when the next
            product comes out and we'll send you updates along the way. The next
            one should be out in 2 weeks!
          </div>
          <div
            className={styles.bigButton}
            onClick={() => this.props.history.push("/")}
          >
            Go Home
          </div>
        </div>
        <div className={styles.imageContainer}>
          <img
            className={styles.natureImage}
            alt="Look!"
            src="https://i.imgur.com/djWAckr.png"
          ></img>
        </div>
        <div className={styles.note}>
          <b>A note from the creator: </b> It's hard for me to express how
          grateful I am over text that you spent time looking at what I've
          built. I love seeing something built from nothing to something. If you
          are someone that builds things,{" "}
          <a href="https://twitter.com/shahgaleeb">please share it with me</a>,
          I will be your champion! And I am excited to share what I build with
          you <b>-Shah</b>
        </div>
        <div
          className={styles.bigButton}
          onClick={() => this.props.history.push("/")}
        >
          Go Home
        </div>
      </div>
    );
  }
}

export default withRouter(ThankYou);
