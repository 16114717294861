import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "./DaysInput.module.scss";
import NavBar from "../home/NavBar.jsx";
import { Helmet } from "react-helmet";
import Footer from "../home/Footer.jsx";

class DaysInput extends Component {
  constructor() {
    super();
    this.state = {
      dateString: "2024-12-25"
    };
  }

  componentDidMount() {}

  setDate() {
    const { dateString } = this.state;

    this.props.history.push("/daysuntil/" + dateString);
  }

  renderHelmet() {
    return (
      <Helmet>
        <title>How many days until</title>
        <meta
          name="description"
          content="Calculate how many more days until a certain date"
        />
        <meta
          name="keywords"
          content="days until, days till, days til, calculate days"
        />
        <meta property="og:url" content="https://www.failflow.com/daysuntil" />
        <meta property="og:title" content="How many days until you die?" />
        <meta
          property="og:description"
          content="Calculate how many more days until a certain date"
        />
        <meta property="og:image" content="https://i.imgur.com/EUaEsBG.png" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/EUaEsBG.png"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/EUaEsBG.png"
        />
      </Helmet>
    );
  }

  handleInputChange(e) {
    this.setState({ dateString: e.target.value });
  }

  renderdaysEntry() {
    let inputClass = styles.dayInput;
    let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (iOS) {
      inputClass = styles.dayInputiOS;
    }

    return (
      <div className={styles.enterDateContainer}>
        <div className={styles.entryContent}>
          <div className={styles.entryHeader}>How many days until</div>
          <div className={styles.entryHelpText}>Enter a date</div>
          {!iOS ? (
            <input
              type="date"
              name="startDate"
              className={inputClass}
              pattern="^[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])$"
              title="Enter a date in the format MM-DD-YYYY i.e. 12-25-2024"
              tabIndex="0"
              required
              value={this.state.dateString}
              onChange={e => this.handleInputChange(e)}
            />
          ) : (
            <div>
              <input
                type="date"
                name="startDate"
                className={inputClass}
                pattern="^[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])$"
                title="Enter a date in the format MM-DD-YYYY i.e. 12-25-2024"
                tabIndex="0"
                required
                value={this.state.dateString}
                onChange={e => this.handleInputChange(e)}
              />
            </div>
          )}

          <div className={styles.enterButton} onClick={() => this.setDate()}>
            DONE
          </div>
          <div className={styles.homeHelpText}>
            Need to calculate how many days until a certain date? Pick a date
            above and click done to see how many days until that date on a page
            that you can bookmark and will recalculate on any visit.
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderHelmet()}
        <NavBar />
        {this.renderdaysEntry()}
        <Footer></Footer>
      </div>
    );
  }
}

export default withRouter(DaysInput);
