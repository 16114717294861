import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import styles from "./ExpertCheckout.module.scss";

import CardSection from "./CardSection";

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmCardPayment(props.clientSec, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: "Jenny Rosen",
        },
      },
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      console.log(result.error.message);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        console.log("payment succeeded");
        props.successfulPayment();
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.header}>Schedule a Time</div>
      <div className={styles.description}>
        Provide your email and payment and I'll send you a scheduling link,
        looking forward to chatting!
      </div>
      <div className={styles.formLabel}>Full Name</div>
      <div className={styles.inputHolder}>
        <input
          className={styles.nameInput}
          name="name"
          type="text"
          placeholder="Full Name"
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className={styles.formLabel}>Email</div>
      <div className={styles.inputHolder}>
        <input
          className={styles.nameInput}
          name="email"
          type="text"
          placeholder="Email Address"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className={styles.formLabel}>Card Details</div>
      <CardSection />
      <button className={styles.confirmOrder} disabled={!stripe}>
        Confirm order
      </button>
    </form>
  );
}
