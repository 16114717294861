import { logOut } from "../actions/user";
import { store } from "../index";
import { isEmpty } from "lodash";

export const getWrapper = async (method, url, headers = {}) => {
  const user = store.getState().user;
  const response = await fetch(url, {
    method,
    headers,
  });
  const peeledRes = await response.json();

  if (
    response.status === 400 &&
    peeledRes.message === "INVALID_TOKEN" &&
    !isEmpty(user)
  ) {
    // if the jwtCheck gives us an invalid token (likely expired or malicious) we sign the user out
    store.dispatch(logOut());
  }
  return peeledRes;
};

export const postWrapper = async (method, url, headers, body) => {
  const user = store.getState().user;
  const response = await fetch(url, {
    method,
    headers,
    body,
  });
  const peeledRes = await response.json();

  if (
    response.status === 400 &&
    peeledRes.message === "INVALID_TOKEN" &&
    !isEmpty(user)
  ) {
    // if the jwtCheck gives us an invalid token (likely expired or malicious) we sign the user out
    store.dispatch(logOut());
  }
  return peeledRes;
};
