import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "./ExpertHome.module.scss";
import { Helmet } from "react-helmet";
import Footer from "../home/Footer.jsx";
import { bindActionCreators } from "redux";
import { setUser, logOut } from "../../actions/user";
import { connect } from "react-redux";
import GoogleSignIn from "../GoogleSignIn";
import { getWrapper } from "../../helpers/fetch";
import { isEmpty } from "lodash";
import ExpertNavBar from "./ExpertNavBar";
import flying from "./flying.svg";

const mapStateToProps = (state) => ({
  user: state.user,
  fullState: state,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setUser, logOut }, dispatch);

class ExportHome extends Component {
  componentDidMount() {
    const { user } = this.props;
    // lets refresh user on mount
    this.refreshUser();
    // this.props.logOut();

    if (isEmpty(user)) {
      console.log("nothing to see here");
    } else {
      console.log("wowie", user);
    }
  }

  componentDidUpdate(prevProps) {
    // const { user } = this.props;
    console.log(prevProps);
    // if (isEmpty(user)) {
    //   console.log("nothing to see here");
    // } else {
    //   this.props.history.push("/expert/form");
    // }
  }

  async refreshUser() {
    const { setUser, user } = this.props;
    if (user === {}) return;
    let userInfo = await getWrapper("GET", "/api/refreshUser", {
      Authorization: user.token,
    });
    setUser(userInfo);
  }

  renderHelmet() {
    return (
      <Helmet>
        <title>Hacker News Release Dashboard</title>
        <meta
          name="description"
          content="Release, track and measure a Hacker News post or release"
        />
        <meta
          name="keywords"
          content="Hacker news, comements, release management, software engineer"
        />
        <meta property="og:url" content="https://www.failflow.com/hnrd" />
        <meta property="og:title" content="Hacker News Release Dashboard" />
        <meta
          property="og:description"
          content="Release, track and measure a Hacker News post or release"
        />
        <meta property="og:image" content="https://i.imgur.com/nWR8btF.png" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/nWR8btF.png"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/nWR8btF.png"
        />
      </Helmet>
    );
  }

  logout() {
    this.props.logOut();
  }

  renderSignedOut() {
    const { user } = this.props;

    return (
      <div className={styles.fullContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.flexSplash}>
            <div className={styles.imgContainer}>
              <img src={flying} alt="susan" className={styles.splashImg}></img>
            </div>
            <div className={styles.lefty}>
              <div className={styles.splashHeader}>
                Monetize Your Expertise{" "}
              </div>
              <div className={styles.splashDescription}>
                Make a page, accept payments, set a schedule, in less than a
                minute
              </div>
              {isEmpty(user) ? (
                <div className={styles.googleloginContainer}>
                  <GoogleSignIn
                    label="Get Started"
                    expert={true}
                    successRoute="/expert/form"
                  ></GoogleSignIn>
                </div>
              ) : (
                <div
                  className={styles.dashboardButton}
                  onClick={() => this.props.history.push("/expert/dashboard")}
                >
                  Go to Dashboard
                </div>
              )}
            </div>
          </div>
          <div className={styles.demoContainer}>
            <div className={styles.demoHeader}>
              Help others, share your expertise, and earn money.
            </div>
            <div className={styles.demoSubHeader}>
              Sharing your knowledge has never felt more rewarding.
            </div>
            <div className={styles.demoImageContainer}>
              <img
                className={styles.demoImage}
                alt="home screenshot"
                src="https://i.imgur.com/PYGK4e0.png"
              ></img>
            </div>
          </div>
          <div className={styles.divider}></div>
          <div className={styles.empowerSection}>
            <div className={styles.sectionHeader}>
              Empowering tutors, consultants, and everyday experts
            </div>
            <div className={styles.sectionSubHeader}>
              Consultants don't always look like suits traveling the country
            </div>
            <div className={styles.placeholder}>Placeholder</div>
          </div>
          <div className={styles.divider}></div>
          <div className={styles.empowerSection}>
            <div className={styles.sectionHeader}>Another section heading</div>
            <div className={styles.sectionSubHeader}>
              An important subsection header{" "}
            </div>
            <div className={styles.placeholder}>Placeholder</div>
          </div>
          <div className={styles.imgContainer}>
            <img src={flying} alt="susan" className={styles.splashImg}></img>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={styles.all}>
        {this.renderHelmet()}
        <ExpertNavBar></ExpertNavBar>
        {this.renderSignedOut()}
        <Footer></Footer>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ExportHome)
);
