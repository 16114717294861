import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { MdClose } from "react-icons/md";
import styles from "./OverlayWrapper.module.scss";

class OverlayWrapper extends Component {
  render() {
    return (
      <div>
        <div
          className={styles.overlayBackground}
          onClick={() => this.props.history.goBack()}
        />
        <div className={styles.overlayContainer}>
          <div
            className={styles.cancel}
            onClick={() => this.props.history.goBack()}
          >
            <MdClose />
          </div>
          <div className={styles.overlayContent}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default withRouter(OverlayWrapper);
