import { Fragment } from 'react'
import { useSelector, } from 'react-redux'
import { Popover, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import NewSignIn from './newFailflow/NewSignIn'

const navigation = [
  { name: 'Product', href: '#product' },
  { name: 'Tools', href: '/tools' },
//   { name: 'Blog', href: 'https://www.failflow.com/blog' },
]

export default function Example() {
  const user = useSelector((state) => state.user)

  return (
    <div className="relative overflow-hidden">
      <Popover as="header" className="relative">
        <div className="bg-white pt-6">
          <nav
            className="relative mx-auto flex max-w-7xl items-center justify-between px-4 sm:px-6"
            aria-label="Global"
          >
            <div className="flex flex-1 items-center">
              <div className="flex w-full items-center justify-between md:w-auto">
                <a href="/">
                  <span className="sr-only">FailFlow</span>
                  <img
                    className="mb-1 h-6 w-auto sm:h-6"
                    src="https://i.imgur.com/PAusHyM.jpeg"
                    alt=""
                  />
                </a>
                <div className="-mr-2 flex items-center md:hidden">
                  <Popover.Button className="focus-ring-inset inline-flex items-center justify-center rounded-md  p-2 text-gray-400 focus:outline-none focus:ring-2 focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="hidden space-x-8 md:ml-10 md:flex">
                {navigation.map((item) => (
                  <a key={item.name} href={item.href} className="text-base font-medium text-greay-900 hover:text-gray-800">
                    {item.name}
                  </a>
                ))}
              </div>
            </div>

            {user.user_email ?
              <div className="hidden md:flex md:items-center md:space-x-6">
                <a
                  href="/dashboard"
                  className="inline-flex items-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-base font-medium text-white hover:bg-blue-600"
                >
                  Go to Dashboard
                </a>
              </div> :

              <div className="hidden md:flex md:items-center md:space-x-6">
                <a href="/signup" className="text-base font-medium text-greay-900 hover:text-gray-800">
                  Log in
                </a>
                <a
                  href="/signup"
                  className="inline-flex items-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-base font-medium text-white hover:bg-blue-600"
                >
                  Sign up
                </a>
              </div>}

         
          </nav>
        </div>

        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel focus className="absolute inset-x-0 top-0 z-10 origin-top transform p-2 transition md:hidden">
            <div className="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
              <div className="flex items-center justify-between px-5 pt-4">
                <div>
                  <a href='/'>
                    <img
                      className="mb-1 h-6 w-auto sm:h-6"
                      src="https://i.imgur.com/PAusHyM.jpeg"
                      alt=""
                    />
                  </a>
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="pt-5 pb-6">
                <div className="space-y-1 px-2">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="mt-6 px-5">
                  <a
                    href="/signup"
                    className="block w-full rounded-md bg-blue-600 py-3 px-4 text-center font-medium text-white shadow hover:bg-blue-700"
                  >
                    Sign up
                  </a>
                </div>
                <div className="mt-6 px-5">
                  <p className="text-center text-base font-medium text-gray-500">
                    Or{' '}
                    <a href="/signup" className="text-gray-900 hover:underline">
                      Log in
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>

      <main>
        <div className="bg-white pt-10 sm:pt-16 lg:overflow-hidden lg:pt-16 lg:pb-14">
          <div className="mx-auto max-w-7xl lg:px-8">
            <div className="lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:flex lg:items-center lg:px-0 lg:text-left">
                <div className="lg:py-24">

                  <h1 className="text-4xl font-bold tracking-tight text-grey-800 sm:mt-5 sm:text-6xl  xl:text-6xl">
                    <span className="block">You're going to <span className=" text-blue-600" >die.</span></span>
                    <span className="block">Make the most of it.</span>
                  </h1>
                  <p className="mt-3 text-base text-grey-700 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                    Daily activities and reminders to help you live more intentionally

                  </p>
                  <div className="mt-5">
                    <div className="sm:flex">
                      <div className="">
                        {user.user_email ? <button
                          // type="submit"
                          onClick={() => { window.location.href = '/dashboard'; }}
                          className="flex w-full rounded-md bg-blue-500 py-3 pl-5 pr-3 font-semibold text-white shadow hover:bg-blue-600 "
                        >
                          Go to Dashboard <ChevronRightIcon className="h-6 w-6" aria-hidden="true"></ChevronRightIcon>
                        </button> : <button
                          // type="submit"
                          onClick={() => { window.location.href = '/signup'; }}
                          className="flex w-full rounded-md bg-blue-500 py-3 pl-5 pr-3 font-semibold text-white shadow hover:bg-blue-600 "
                        >
                          Sign up for free <ChevronRightIcon className="h-6 w-6" aria-hidden="true"></ChevronRightIcon>
                        </button>}
                      </div>
                      <a href='/life'>
                        <div className="pl-5">
                          <button
                            className="flex w-full rounded-md py-3  pr-3 font-semibold text-black hover:opacity-70 md:pl-5"
                          >
                            Try the free life calendar <ChevronRightIcon className="h-6 w-6" aria-hidden="true"></ChevronRightIcon>
                          </button>
                        </div>
                      </a>

                    </div>

                    {user.user_email ? null : <NewSignIn noButton={true} ></NewSignIn>}

                    <div className='mt-10'>
                      <div className='text-base font-semibold text-gray-600'>
                        As featured in
                      </div>
                      <div className='flex mt-4'>
                        <a href='https://www.producthunt.com/newsletter/3401-you-will-die-in-x-weeks' target="_blank" rel="noopener noreferrer">
                          <img className='max-h-10 ' src='https://i.imgur.com/jF7q0OK.png' alt="product hunt proof"></img>
                        </a>
                        <a href='https://gigazine.net/gsc_news/en/20200109-how-many-weeks-until-you-die/' target="_blank" rel="noopener noreferrer">
                          <img className='max-h-10 ml-6' src='https://i.imgur.com/VdzX01b.png' alt="gigazine proof"></img>

                        </a>
                        <a href='https://tabi-labo.com/293783/wt-how-many-weeks-until-you-die' target="_blank" rel="noopener noreferrer">
                          <img className='max-h-12 ml-8' src='https://i.imgur.com/AXZDLi4.png' alt="tabi labo proof"></img>
                        </a>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="mx-auto mt-12 -mb-16 sm:-mb-48 lg:relative lg:m-0">
                <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 ">
                  <img
                    className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none lg:pl-14 "
                    src="https://i.imgur.com/vbeAkjP.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* More main page content here... */}
      </main>
    </div>
  )
}