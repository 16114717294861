import React from "react";
import { Provider } from "react-redux";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import Home from "./components/home/Home";
import Dashboard from "./components/home/newFailflow/Dashboard";
import SignUp from "./components/home/newFailflow/SignUp";
import ReflectionInput from "./components/home/newFailflow/weeksCopy/ReflectionInput"
import ReflectionView from "./components/home/newFailflow/weeksCopy/ReflectionView"


import DieView from "./components/die/DieView";
import DieInput from "./components/die/DieInput";
import Missing from "./components/Missing";
import { createBrowserHistory } from "history";
import { StripeProvider } from "react-stripe-elements";
import ReactGA from "react-ga";
import Pay from "./components/pay/Pay";
import ThankYou from "./components/pay/ThankYou";

import WeeksInput from "./components/dates/WeeksInput";
import WeeksUntil from "./components/dates/WeeksUntil";
import WeeksUntilChristmas from "./components/dates/WeeksUntilChristmas";
import WeeksUntilThanksgiving from "./components/dates/WeeksUntilThanksgiving";
import WeeksUntilEndOfYear from "./components/dates/WeeksUntilEndOfYear";
import ThreeWeeks from "./components/dates/ThreeWeeks";
import TwoWeeks from "./components/dates/TwoWeeks";
import OneWeek from "./components/dates/OneWeek";

import DaysInput from "./components/dates/DaysInput";
import DaysUntil from "./components/dates/DaysUntil";
import HNTrackContainer from "./components/hntrack/HNTrackContainer";
import HNAccount from "./components/hntrack/HNAccount";
import HNView from "./components/hntrack/HNView";
import HNPay from "./components/hntrack/HNPay";
import Game from "./components/game/Game";
import Toast from "./Toast";
import Tools from "./components/home/Tools";
import PrivacyPolicy from "./components/home/PrivacyPolicy";
// import Blog from "./components/blog/Blog";
// import MyFirstPHPost from "./components/blog/MyFirstPHPost";
import HumanSounds from "./components/humansounds/HumanSounds";
import HumanPay from "./components/humansounds/HumanPay";
import DownloadAudio from "./components/humansounds/DownloadAudio";
import ExpertHome from "./components/expert/ExpertHome";
import ExpertForm from "./components/expert/ExpertForm";
import ExpertPage from "./components/expert/ExpertPage";
import ExpertDashboard from "./components/expert/ExpertDashboard";
import SomethingNew from "./components/somethingNew/SomethingNew";

let history = undefined;
let stripePublicKey = "pk_test_WKI27MGSV7vd4oBxf8zEMnFS";

if (typeof window !== "undefined" && window.location) {
  if (window.location.host === "failflow.com") {
    stripePublicKey = "pk_live_WiQo0cqLPLGnGJBULP6uaPYq";
  }
}

if (typeof window !== "undefined") {
  history = createBrowserHistory();

  history.listen((location, action) => {
    ReactGA.pageview(location.pathname);
  });
}

const Root = ({ store }) => (
  <StripeProvider apiKey={stripePublicKey}>
    <Provider store={store}>
      <Toast></Toast>
      <Router history={history}>
        <Switch>
          {/*start common routes */}
          <Route exact path="/" component={Home} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/dashboard" component={Dashboard} />

          {/*start mortality routes */}
          <Route exact path="/mortality" component={ReflectionInput} />
          <Route exact path="/mortality/:bday" component={ReflectionView} />
          <Route exact path="/mortality/:bday/phelps" component={ReflectionView} />
          <Route exact path="/mortality/:bday/biles" component={ReflectionView} />
          <Route exact path="/mortality/:bday/jobs" component={ReflectionView} />
          <Route exact path="/mortality/:bday/lawrence" component={ReflectionView} />
          <Route exact path="/mortality/:bday/einstein" component={ReflectionView} />
          <Route exact path="/mortality/:bday/jordan" component={ReflectionView} />
          <Route exact path="/mortality/:bday/gates" component={ReflectionView} />
          <Route exact path="/mortality/:bday/legend" component={ReflectionView} />
          <Route exact path="/mortality/:bday/blakely" component={ReflectionView} />
          <Route exact path="/mortality/:bday/jfk" component={ReflectionView} />
          <Route exact path="/mortality/:bday/jobsphone" component={ReflectionView} />
          <Route exact path="/mortality/:bday/maya" component={ReflectionView} />
          <Route exact path="/mortality/:bday/obama" component={ReflectionView} />
          <Route exact path="/mortality/:bday/ochoa" component={ReflectionView} />
          <Route exact path="/mortality/:bday/dolores" component={ReflectionView} />
          <Route exact path="/mortality/:bday/grace" component={ReflectionView} />
          <Route exact path="/mortality/:bday/gandhi" component={ReflectionView} />
          <Route exact path="/mortality/:bday/susan" component={ReflectionView} />
          {/*end die routes */}

          {/* these are the old blog routes, we use gatsby now, if it breass we can come back to this */}
          {/* <Route exact path="/blog" component={Blog} /> */}
          {/* <Route
            exact
            path="/blog/my-first-product-hunt"
            component={MyFirstPHPost}
          /> */}

          <Route exact path="/tools" component={Tools} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/humansounds" component={HumanSounds} />
          <Route exact path="/humansounds/pay" component={HumanPay} />
          <Route
            exact
            path="/humansounds/bG9sb2xvbG9sc2VjdXJpdHk"
            component={DownloadAudio}
          />

          <Route exact path="/somethingnew" component={SomethingNew} />

          <Route exact path="/bestgame" component={Game}></Route>
          <Route
            exact
            path="/signup"
            render={(props) => <Home {...props} modalUp={true} />}
          />
          <Route exact path="/pay" component={Pay} />
          <Route exact path="/thankyou" component={ThankYou} />
          <Route exact path="/account" component={HNAccount} />
          {/*end common routes */}

          {/*start expert routes */}
          <Route exact path="/expert" component={ExpertHome} />
          <Route exact path="/expert/form" component={ExpertForm} />
          <Route exact path="/expert/dashboard" component={ExpertDashboard} />
          <Route exact path="/ex/:siteName" component={ExpertPage} />

          {/*end expert routes */}

          {/*start practice routes */}
          <Route path="/hnrd" component={HNTrackContainer} />
          <Route path="/hnitem/:id" component={HNView} />
          <Route exact path="/hnupgrade" component={HNPay} />

          {/*end practice routes */}

          {/*start die routes */}
          <Route exact path="/die" component={DieInput} />
          <Route exact path="/die/:bday" component={DieView} />
          <Route exact path="/die/:bday/phelps" component={DieView} />
          <Route exact path="/die/:bday/biles" component={DieView} />
          <Route exact path="/die/:bday/jobs" component={DieView} />
          <Route exact path="/die/:bday/lawrence" component={DieView} />
          <Route exact path="/die/:bday/einstein" component={DieView} />
          <Route exact path="/die/:bday/jordan" component={DieView} />
          <Route exact path="/die/:bday/gates" component={DieView} />
          <Route exact path="/die/:bday/legend" component={DieView} />
          <Route exact path="/die/:bday/blakely" component={DieView} />
          <Route exact path="/die/:bday/jfk" component={DieView} />
          <Route exact path="/die/:bday/jobsphone" component={DieView} />
          <Route exact path="/die/:bday/maya" component={DieView} />
          <Route exact path="/die/:bday/obama" component={DieView} />
          <Route exact path="/die/:bday/ochoa" component={DieView} />
          <Route exact path="/die/:bday/dolores" component={DieView} />
          <Route exact path="/die/:bday/grace" component={DieView} />
          <Route exact path="/die/:bday/gandhi" component={DieView} />
          <Route exact path="/die/:bday/susan" component={DieView} />
          <Route exact path="/life" component={DieInput} />
          {/*end die routes */}

          {/*start datecalc routes */}
          <Route exact path="/weeksuntil" component={WeeksInput} />
          <Route
            exact
            path="/weeksuntil/3-weeks-from-today"
            component={ThreeWeeks}
          />
          <Route
            exact
            path="/weeksuntil/2-weeks-from-today"
            component={TwoWeeks}
          />
          <Route
            exact
            path="/weeksuntil/1-week-from-today"
            component={OneWeek}
          />
          <Route
            exact
            path="/weeksuntil/christmas"
            component={WeeksUntilChristmas}
          />
          <Route
            exact
            path="/weeksuntil/endofyear"
            component={WeeksUntilEndOfYear}
          />
          <Route
            exact
            path="/weeksuntil/thanksgiving"
            component={WeeksUntilThanksgiving}
          />
          <Route exact path="/weeksuntil/:weeks" component={WeeksUntil} />
          <Route exact path="/daysuntil" component={DaysInput} />
          <Route exact path="/daysuntil/:days" component={DaysUntil} />
          {/*end datecalc routes */}

          <Route exact path="/404" component={Missing} />
          <Redirect to="/404"></Redirect>
        </Switch>
      </Router>
    </Provider>
  </StripeProvider>
);

export default Root;
