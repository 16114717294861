import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "./HNAccount.module.scss";
import HNNavBar from "./HNNavBar.jsx";
import { Helmet } from "react-helmet";
import Footer from "../home/Footer.jsx";
import { bindActionCreators } from "redux";
import { logOut } from "../../actions/user";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ logOut }, dispatch);

class HNTrack extends Component {
  renderHelmet() {
    return (
      <Helmet>
        <title>Hacker News Release Dashboard</title>
        <meta
          name="description"
          content="Release, track and measure a Hacker News post or release"
        />
        <meta
          name="keywords"
          content="Hacker news, comements, release management, software engineer"
        />
        <meta property="og:url" content="https://www.failflow.com/hnrd" />
        <meta property="og:title" content="Hacker News Release Dashboard" />
        <meta
          property="og:description"
          content="Release, track and measure a Hacker News post or release"
        />
        <meta property="og:image" content="https://i.imgur.com/nWR8btF.png" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/nWR8btF.png"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/nWR8btF.png"
        />
      </Helmet>
    );
  }

  logout() {
    const { history, logOut } = this.props;
    logOut();
    history.push("/hnrd");
  }

  renderUpgradeSection() {
    const { user } = this.props;
    const { accountType } = user;

    const proSubscriber = accountType && accountType >= 0;
    if (proSubscriber) {
      return (
        <div className={styles.upgradeContainer}>
          <div className={styles.upgradeHeader}>
            Thank you for being a Pro user!
          </div>
          <div className={styles.benefit}>
            If you want to cancel your subscription, send us an email at{" "}
            <a href="mailto:shah@failflow.com">shah@failflow.com</a>. Your
            account will be canceled within 24 hours. If you cancel, at most, 48
            hours after a renewal, we will refund you. You will still have a Pro
            suscription until your billing period ends after you cancel.
          </div>
        </div>
      );
    }
    return (
      <div className={styles.upgradeContainer}>
        <div className={styles.upgradeHeader}>Upgrade Account</div>
        <div>
          <div className={styles.benefit}>Unlimited Trackers</div>
          <div className={styles.benefit}>Priority Support</div>
          <div className={styles.benefit}>
            <i>Press Upgrade to see future updates</i>
          </div>
        </div>
        <div
          onClick={() => this.props.history.push("/hnupgrade")}
          className={styles.upgradeButton}
        >
          Upgrade
        </div>
      </div>
    );
  }

  renderSignedIn() {
    const { user } = this.props;
    const { accountType } = user;

    const proSubscriber = accountType && accountType >= 0;
    const accountTypeString = proSubscriber ? "Pro" : "Free";

    return (
      <div className={styles.fullContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.header}>Account Overview</div>
          <div className={styles.subHeader}>Email</div>
          <div className={styles.description}>{user.user_email}</div>
          <div className={styles.signOutButton} onClick={() => this.logout()}>
            Sign Out
          </div>
          <div className={styles.subHeader}>Account Type</div>
          <div className={`${styles.description} ${styles[accountTypeString]}`}>
            {accountTypeString}
          </div>
          {this.renderUpgradeSection()}
          <div className={styles.subHeader}>Contact Us</div>
          <div className={styles.descriptionContact}>
            If you have any questions about the product or about future
            releases, reach us by email at{" "}
            <a href="mailto:shah@failflow.com">shah@failflow.com</a>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderHelmet()}
        <HNNavBar />
        {this.renderSignedIn()}
        <Footer></Footer>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HNTrack)
);
