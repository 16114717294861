import React, { Component } from "react";
import OverlayWrapper from "./OverlayWrapper.jsx";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styles from "./AccomplishmentOverlay.module.scss";

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

class AccomplishmentOverlay extends Component {
  goBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  render() {
    return (
      <OverlayWrapper>
        <div className={styles.contentContainer}>
          <div className={styles.heroImageContainer}>
            <img
              src={this.props.imageLink}
              alt={this.props.name}
              className={styles.heroImage}
            ></img>
          </div>
          <div className={styles.heroText}>
            <div className={styles.heroName}>{this.props.name}</div>
            <div className={styles.heroAge}>
              Week of their life:{" "}
              <b>{this.props.weekNum.toLocaleString("en")}</b>
            </div>
            <div className={styles.heroAge}>
              Age that week: <b>{this.props.age}</b>
            </div>
            <div className={styles.heroAccomplishmentLabel}>
              Accomplishment:
            </div>
            <div className={styles.heroDesc}>{this.props.description}</div>
          </div>
        </div>
      </OverlayWrapper>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AccomplishmentOverlay)
);
