import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "./Game.module.scss";
import { Helmet } from "react-helmet";

class Game extends Component {
  constructor() {
    super();
    this.state = {
      introDone: false,
      fightingBoss: false,
      bossHealth: 100,
      yourHealth: 100,
      showMessage: false,
      atFarm: false,
      atFarmInitial: true,
      fightingCritter: false,
      strength: 1,
      defence: 1,
      critterType: "",
      critterHealth: 10,
      finished: false,
    };
  }

  componentDidMount() {}

  renderHelmet() {
    return (
      <Helmet>
        <title>game</title>
        <meta name="description" content="orange blob takes on blue blob" />
        <meta name="keywords" content="game, blobs, rpg" />
        <meta property="og:url" content="https://www.failflow.com/bestgame" />
        <meta property="og:title" content="game" />
        <meta property="og:description" content="play game" />
        <meta property="og:image" content="https://i.imgur.com/hL0WQQh.png" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/hL0WQQh.png"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/hL0WQQh.png"
        />
      </Helmet>
    );
  }

  renderIntro() {
    return (
      <>
        <div className={`${styles.nesContainer} ${styles.withTitle}`}>
          <p className={styles.title}>Welcome</p>
          <div>Welcome to Game!</div>
          <div>You are an orange blob</div>
          <div className={styles.orangeBlob}></div>
          <div>Blue blob killed your family and is about to attack you</div>
          <div
            onClick={() => {
              this.setState({ introDone: true, fightingBoss: true });
            }}
            className={`${styles.nesBtn} ${styles.isPrimary}`}
          >
            Continue
          </div>
        </div>
        <div className={styles.productHolder}>
          <a
            href="https://www.producthunt.com/posts/5-minute-rpg?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-5-minute-rpg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=197878&theme=light"
              alt="5-Minute RPG - A quick, pure React game in the browser | Product Hunt Embed"
              style={{ width: "250px", height: "54px" }}
              width="250px"
              height="54px"
            />
          </a>
        </div>
      </>
    );
  }

  renderFinished() {
    return (
      <div className={`${styles.nesContainer} ${styles.withTitle}`}>
        <p className={styles.title}>The farm</p>
        <div className={styles.purpleBlob}></div>
        <div>red blob: i see you have killed my son ...</div>
        <div></div>
        <div className={styles.message}>red blob begins shaking...</div>
        <div></div>
        <div></div>
        <div className={styles.message}>
          part 2 not available yet, sign up to get notified!
        </div>
        <div></div>
        <div
          onClick={() => {
            window.location.href = "/signup";
          }}
          className={`${styles.nesBtn} ${styles.isPrimary}`}
        >
          Sign up
        </div>
        <div></div>
      </div>
    );
  }

  renderFarm() {
    return (
      <div className={`${styles.nesContainer} ${styles.withTitle}`}>
        <p className={styles.title}>The farm</p>
        <div className={styles.redBlob}></div>

        {this.state.atFarmInitial ? (
          <div className={styles.message}>
            red blob: i found you passed out in the field. feel free to stay
            here, but kill some critters if you want to stay{" "}
          </div>
        ) : (
          <div className={styles.message}>
            red blob: looks like you're getting better. stay safe
          </div>
        )}
        <div></div>
        <div
          onClick={() => {
            if (this.state.atFarmInitial) {
              this.setState({ atFarmInitial: false });
            }
            let critterType = "attack";
            if (Math.random() > 0.5) {
              critterType = "defence";
            }
            this.setState({
              atFarm: false,
              fightingCritter: true,
              critterType,
              critterHealth: 10,
              yourHealth: 100,
              showMessage: false,
            });
          }}
          className={`${styles.nesBtn} ${styles.isPrimary}`}
        >
          Fight a critter
        </div>
        <div></div>
        <div
          onClick={() => {
            this.setState({
              atFarm: false,
              fightingBoss: true,
              yourHealth: 100,
              bossHealth: 100,
              showMessage: false,
            });
          }}
          className={`${styles.nesBtn} ${styles.isPrimary}`}
        >
          Challenge blue blob
        </div>
        <div>
          <div className={styles.message}></div>
          Current Strength: {this.state.strength}
          <div className={styles.message}></div>
          Current Defence: {this.state.defence}
        </div>
      </div>
    );
  }

  attack() {
    this.setState({
      bossHealth: this.state.bossHealth - 2 * this.state.strength,
      showMessage: true,
    });
  }

  attackCritter() {
    if (this.state.strength > 5) {
      this.setState({
        critterHealth: this.state.critterHealth - 1 * this.state.strength,
        showMessage: true,
      });
    } else {
      this.setState({
        critterHealth: this.state.critterHealth - 1.3 * this.state.strength,
        showMessage: true,
      });
    }
  }

  renderBox() {
    if (this.state.yourHealth <= 0) {
      return (
        <div className={styles.choices}>
          <div className={`${styles.nesContainer} ${styles.withTitle}`}>
            <p className={styles.title}>Options</p>{" "}
            <div
              onClick={() =>
                this.setState({
                  fightingBoss: false,
                  atFarm: true,
                })
              }
              className={styles.nesBtn}
            >
              Continue
            </div>
            <div></div>
            <div className={styles.message}>
              You are fading away ... you find yourself at a farm nearby
            </div>
          </div>
        </div>
      );
    }

    if (this.state.bossHealth <= 0) {
      return (
        <div className={styles.choices}>
          <div className={`${styles.nesContainer} ${styles.withTitle}`}>
            <p className={styles.title}>Options</p>{" "}
            <div
              onClick={() => {
                this.setState({
                  fightingBoss: false,
                  finished: true,
                });
              }}
              className={styles.nesBtn}
            >
              Continue
            </div>
            <div></div>
            <div>
              You defeated blue blob! but something doesn't feel right ... you
              head back to the farm
            </div>
          </div>
        </div>
      );
    }
    if (this.state.showMessage) {
      return (
        <div className={styles.choices}>
          <div className={`${styles.nesContainer} ${styles.withTitle}`}>
            <p className={styles.title}>Options</p>{" "}
            <div
              onClick={() =>
                this.setState({
                  showMessage: false,
                  yourHealth:
                    this.state.yourHealth - 40 * (1 - this.state.defence / 60),
                })
              }
              className={styles.nesBtn}
            >
              Continue
            </div>
            <div>You did some damage</div>
            <div className={styles.message}>blue blob: "Ouch"</div>
            <div></div>
            <div className={styles.message}>
              Blue blob is getting ready to attack
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.choices}>
          <div className={`${styles.nesContainer} ${styles.withTitle}`}>
            <p className={styles.title}>Options</p>

            <div onClick={() => this.attack()} className={styles.nesBtn}>
              Attack
            </div>
          </div>
        </div>
      );
    }
  }

  renderBoss() {
    return (
      <div>
        <div className={styles.rightEnemy}>
          <p>blue blob</p>
          <progress
            value={this.state.bossHealth}
            max="100"
            className={`${styles.nesProgress} ${styles.isSuccess}`}
          ></progress>
          <div className={styles.blueBlob}></div>
        </div>

        <div className={styles.leftMe}>
          <p>orange blob</p>

          <progress
            value={this.state.yourHealth}
            max="100"
            className={`${styles.nesProgress} ${styles.isSuccess}`}
          ></progress>
          <div className={styles.orangeBlob}></div>
        </div>
        {this.renderBox()}
      </div>
    );
  }

  renderCritter() {
    let critterName;
    if (this.state.critterType === "attack") {
      critterName = "aggressive critter";
    } else critterName = "armored critter";
    return (
      <div>
        <div className={styles.rightEnemy}>
          <p>{critterName}</p>
          <progress
            value={this.state.critterHealth}
            max="10"
            className={`${styles.nesProgress} ${styles.isSuccess}`}
          ></progress>
          <div className={styles[this.state.critterType]}></div>
        </div>

        <div className={styles.leftMe}>
          <p>orange blob</p>

          <progress
            value={this.state.yourHealth}
            max="100"
            className={`${styles.nesProgress} ${styles.isSuccess}`}
          ></progress>
          <div className={styles.orangeBlob}></div>
        </div>
        {this.renderCritterBox()}
      </div>
    );
  }

  renderCritterBox() {
    const { critterType, defence } = this.state;
    if (this.state.yourHealth <= 0) {
      return (
        <div className={styles.choices}>
          <div className={`${styles.nesContainer} ${styles.withTitle}`}>
            <p className={styles.title}>Options</p>{" "}
            <div
              onClick={() =>
                this.setState({
                  fightingCritter: false,
                  atFarm: true,
                })
              }
              className={styles.nesBtn}
            >
              Continue
            </div>
            <div></div>
            <div className={styles.message}>
              You are fading away ... you find yourself at the farm
            </div>
          </div>
        </div>
      );
    }
    if (this.state.critterHealth <= 0) {
      return (
        <div className={styles.choices}>
          <div className={`${styles.nesContainer} ${styles.withTitle}`}>
            <p className={styles.title}>Options</p>{" "}
            <div
              onClick={() => {
                if (critterType === "attack") {
                  this.setState({
                    strength: this.state.strength + 2,
                  });
                }
                if (critterType === "defence") {
                  this.setState({
                    defence: this.state.defence + 2,
                  });
                }
                this.setState({
                  fightingCritter: false,
                  atFarm: true,
                });
              }}
              className={styles.nesBtn}
            >
              Continue
            </div>
            <div></div>
            <div className={styles.message}>
              You defeated the critter! you feel stronger
            </div>
          </div>
        </div>
      );
    }
    if (this.state.showMessage) {
      return (
        <div className={styles.choices}>
          <div className={`${styles.nesContainer} ${styles.withTitle}`}>
            <p className={styles.title}>Options</p>{" "}
            <div
              onClick={() =>
                this.setState({
                  showMessage: false,
                  yourHealth: this.state.yourHealth - 12 * (1 - defence / 60),
                })
              }
              className={styles.nesBtn}
            >
              Continue
            </div>
            <div className={styles.message}>You did some damage</div>
            <div></div>
            <div></div>
            <div className={styles.message}>
              Critter is getting ready to attack
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.choices}>
          <div className={`${styles.nesContainer} ${styles.withTitle}`}>
            <p className={styles.title}>Options</p>

            <div onClick={() => this.attackCritter()} className={styles.nesBtn}>
              Attack
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        {this.renderHelmet()}
        <div className={styles.gameContainer}>
          {!this.state.introDone ? this.renderIntro() : null}
          {this.state.fightingBoss ? this.renderBoss() : null}
          {this.state.atFarm ? this.renderFarm() : null}
          {this.state.fightingCritter ? this.renderCritter() : null}
          {this.state.finished ? this.renderFinished() : null}
        </div>
      </div>
    );
  }
}

export default withRouter(Game);
