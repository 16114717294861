import React, { Component } from "react";
import { withRouter, Switch, Route } from "react-router-dom";
import AccomplishmentOverlay from "./AccomplishmentOverlay.jsx";

const achievements = {
    michaelPhelps: {
      name: "Michael Phelps",
      age: 15,
      weekNum: 821,
      description: "Breaks his first world record - the 200 m butterfly.",
      imageLink: "https://i.imgur.com/a0BjXMY.png"
    },
    simoneBiles: {
      name: "Simone Biles",
      age: 19,
      weekNum: 1012,
      description:
        "Wins her first gold medal in the Rio Olympics.  Simone ends up with four golds that year.",
      imageLink: "https://i.imgur.com/CmMolSn.jpeg"
    },
    steveJobs: {
      name: "Steve Jobs",
      age: 21,
      weekNum: 1101,
      description: "Along with Steve Wozniak, Steve starts Apple in Los Altos.",
      imageLink: "https://i.imgur.com/9wTezJ9.jpeg"
    },
    jenniferLawrence: {
      name: "Jennifer Lawrence",
      age: 22,
      weekNum: 1175,
      description:
        'Attends the 85th Academy Awards this week.  For her performace in "Silver Linings Playbook", she wins an Oscar for Best Actress.',
      imageLink: "https://i.imgur.com/L0cj3Nv.png"
    },
    albertEinstein: {
      name: "Albert Einstein",
      age: 26,
      weekNum: 1369,
      description:
        "Releases the first of his four groundbreaking papers of 1905.  The year many consider his annus mirabilis (miracle year).",
      imageLink:
        "https://i.imgur.com/IylocTa.jpeg"
    },
    michaelJordan: {
      name: "Michael Jordan",
      age: 28,
      weekNum: 1502,
      description:
        "The week of the '91 NBA Championships, where Michael helps the Bulls take the first of six championships during his career.",
      imageLink: "https://i.imgur.com/wHUY2l7.png"
    },
    billGates: {
      name: "Bill Gates",
      age: 31,
      weekNum: 1617,
      description:
        "Though Microsoft went public when Bill Gates was 28, it was this week in his life that he became a billionaire at 31.",
      imageLink: "https://i.imgur.com/Cw235Mj.jpeg"
    },
    ellenOchoa: {
      name: "Ellen Ochoa",
      age: 34,
      weekNum: 1821,
      description:
        "Takes first mission to space on STS-56 ATLAS-2 Discovery in 1993. Becomes first Hispanic woman to go to space.",
      imageLink: "https://i.imgur.com/c65wbLi.jpeg"
    },
  
    johnLegend: {
      name: "John Legend",
      age: 39,
      weekNum: 2072,
      description:
        "Becomes the youngest person to achieve EGOT status, meaning he's won an Emmy, Grammy, Oscar and Tony.  This week highlights his Emmy win.",
      imageLink: "https://i.imgur.com/oo8qRm0.jpeg"
    },
    saraBlakely: {
      name: "Sara Blakely",
      age: 41,
      weekNum: 2139,
      description:
        "Sara Blakely was 27 when she started the first version of Spanx. But, it was this week in her life that she became the youngest self-made female billionaire at the time.",
      imageLink: "https://i.imgur.com/PVsOwhE.jpeg"
    },
    jfk: {
      name: "JFK",
      age: 43,
      weekNum: 2267,
      description:
        "This week in John F. Kennedy's life marks the end of the 1960 election, when JFK becomes the youngest president in the history of the U.S.",
      imageLink: "https://i.imgur.com/idYhO3y.jpeg"
    },
    barackObama: {
      name: "Barack Obama",
      age: 47,
      weekNum: 2465,
      description:
        "Becomes the 44th president of the U.S. and first black president of the U.S. after the 2008 election.",
      imageLink: "https://i.imgur.com/ThoIcvs.jpeg"
    },
    steveJobsiPhone: {
      name: "Steve Jobs (but older)",
      age: 51,
      weekNum: 2706,
      description:
        'This week marks the first reveal of the iPhone at MacWorld 2007, "a phone, a music player, and a breakthrough internet communications device." ',
      imageLink: "https://i.imgur.com/dPkqzi9.jpeg"
    },
  
    gandhi: {
      name: "Mahatma Gandhi",
      age: 60,
      weekNum: 3152,
      description:
        "Leads the salt march in protest of the British-imposed salt tax.",
      imageLink: "https://i.imgur.com/llk7OPR.jpeg"
    },
    graceHopper: {
      name: "Grace Hopper",
      age: 63,
      weekNum: 3295,
      description:
        "After a lifetime of being a pioneer of computer programming, Grace Hopper develops the implementation of standards for testing computer systems and components for the entirety of the Defense Department",
      imageLink: "https://i.imgur.com/N4LcyG4.jpeg"
    },
    doloresHuerta: {
      name: "Dolores Huerta",
      age: 71,
      weekNum: 3742,
      description:
        "Starts the Dolores Huerta Foundation after a lifetime of fighting for worker's, immigrant's and women's rights",
      imageLink: "https://i.imgur.com/Kj5rjJh.png"
    },
    mayaAngelou: {
      name: "Maya Angelou",
      age: 85,
      weekNum: 4434,
      description:
        'Publishes the seventh volume of her autobiography, titled "Mom & Me & Mom"',
      imageLink: "https://i.imgur.com/JwO9kMQ.jpeg"
    },
    susan: {
      name: "Susan B. Anthony",
      age: 81,
      weekNum: 4272,
      description:
        "Creates the International Woman Suffrage Alliance after a life of pushing the women's suffrage movement.",
      imageLink: "https://i.imgur.com/hBMMBrw.jpeg"
    }
  };

class DieOverlayRouter extends Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path="/die/:bday/phelps"
          render={props => (
            <AccomplishmentOverlay
              {...props}
              name={achievements.michaelPhelps.name}
              age={achievements.michaelPhelps.age}
              description={achievements.michaelPhelps.description}
              imageLink={achievements.michaelPhelps.imageLink}
              weekNum={achievements.michaelPhelps.weekNum}
            ></AccomplishmentOverlay>
          )}
        />
        <Route
          exact
          path="/die/:bday/biles"
          render={props => (
            <AccomplishmentOverlay
              {...props}
              name={achievements.simoneBiles.name}
              age={achievements.simoneBiles.age}
              description={achievements.simoneBiles.description}
              imageLink={achievements.simoneBiles.imageLink}
              weekNum={achievements.simoneBiles.weekNum}
            ></AccomplishmentOverlay>
          )}
        />
        <Route
          exact
          path="/die/:bday/jobs"
          render={props => (
            <AccomplishmentOverlay
              {...props}
              name={achievements.steveJobs.name}
              age={achievements.steveJobs.age}
              description={achievements.steveJobs.description}
              imageLink={achievements.steveJobs.imageLink}
              weekNum={achievements.steveJobs.weekNum}
            ></AccomplishmentOverlay>
          )}
        />
        <Route
          exact
          path="/die/:bday/lawrence"
          render={props => (
            <AccomplishmentOverlay
              {...props}
              name={achievements.jenniferLawrence.name}
              age={achievements.jenniferLawrence.age}
              description={achievements.jenniferLawrence.description}
              imageLink={achievements.jenniferLawrence.imageLink}
              weekNum={achievements.jenniferLawrence.weekNum}
            ></AccomplishmentOverlay>
          )}
        />
        <Route
          exact
          path="/die/:bday/einstein"
          render={props => (
            <AccomplishmentOverlay
              {...props}
              name={achievements.albertEinstein.name}
              age={achievements.albertEinstein.age}
              description={achievements.albertEinstein.description}
              imageLink={achievements.albertEinstein.imageLink}
              weekNum={achievements.albertEinstein.weekNum}
            ></AccomplishmentOverlay>
          )}
        />
        <Route
          exact
          path="/die/:bday/jordan"
          render={props => (
            <AccomplishmentOverlay
              {...props}
              name={achievements.michaelJordan.name}
              age={achievements.michaelJordan.age}
              description={achievements.michaelJordan.description}
              imageLink={achievements.michaelJordan.imageLink}
              weekNum={achievements.michaelJordan.weekNum}
            ></AccomplishmentOverlay>
          )}
        />
        <Route
          exact
          path="/die/:bday/gates"
          render={props => (
            <AccomplishmentOverlay
              {...props}
              name={achievements.billGates.name}
              age={achievements.billGates.age}
              description={achievements.billGates.description}
              imageLink={achievements.billGates.imageLink}
              weekNum={achievements.billGates.weekNum}
            ></AccomplishmentOverlay>
          )}
        />
        <Route
          exact
          path="/die/:bday/legend"
          render={props => (
            <AccomplishmentOverlay
              {...props}
              name={achievements.johnLegend.name}
              age={achievements.johnLegend.age}
              description={achievements.johnLegend.description}
              imageLink={achievements.johnLegend.imageLink}
              weekNum={achievements.johnLegend.weekNum}
            ></AccomplishmentOverlay>
          )}
        />
        <Route
          exact
          path="/die/:bday/blakely"
          render={props => (
            <AccomplishmentOverlay
              {...props}
              name={achievements.saraBlakely.name}
              age={achievements.saraBlakely.age}
              description={achievements.saraBlakely.description}
              imageLink={achievements.saraBlakely.imageLink}
              weekNum={achievements.saraBlakely.weekNum}
            ></AccomplishmentOverlay>
          )}
        />
        <Route
          exact
          path="/die/:bday/jfk"
          render={props => (
            <AccomplishmentOverlay
              {...props}
              name={achievements.jfk.name}
              age={achievements.jfk.age}
              description={achievements.jfk.description}
              imageLink={achievements.jfk.imageLink}
              weekNum={achievements.jfk.weekNum}
            ></AccomplishmentOverlay>
          )}
        />
        <Route
          exact
          path="/die/:bday/jobsphone"
          render={props => (
            <AccomplishmentOverlay
              {...props}
              name={achievements.steveJobsiPhone.name}
              age={achievements.steveJobsiPhone.age}
              description={achievements.steveJobsiPhone.description}
              imageLink={achievements.steveJobsiPhone.imageLink}
              weekNum={achievements.steveJobsiPhone.weekNum}
            ></AccomplishmentOverlay>
          )}
        />
        <Route
          exact
          path="/die/:bday/maya"
          render={props => (
            <AccomplishmentOverlay
              {...props}
              name={achievements.mayaAngelou.name}
              age={achievements.mayaAngelou.age}
              description={achievements.mayaAngelou.description}
              imageLink={achievements.mayaAngelou.imageLink}
              weekNum={achievements.mayaAngelou.weekNum}
            ></AccomplishmentOverlay>
          )}
        />
        <Route
          exact
          path="/die/:bday/obama"
          render={props => (
            <AccomplishmentOverlay
              {...props}
              name={achievements.barackObama.name}
              age={achievements.barackObama.age}
              description={achievements.barackObama.description}
              imageLink={achievements.barackObama.imageLink}
              weekNum={achievements.barackObama.weekNum}
            ></AccomplishmentOverlay>
          )}
        />
        <Route
          exact
          path="/die/:bday/ochoa"
          render={props => (
            <AccomplishmentOverlay
              {...props}
              name={achievements.ellenOchoa.name}
              age={achievements.ellenOchoa.age}
              description={achievements.ellenOchoa.description}
              imageLink={achievements.ellenOchoa.imageLink}
              weekNum={achievements.ellenOchoa.weekNum}
            ></AccomplishmentOverlay>
          )}
        />
        <Route
          exact
          path="/die/:bday/dolores"
          render={props => (
            <AccomplishmentOverlay
              {...props}
              name={achievements.doloresHuerta.name}
              age={achievements.doloresHuerta.age}
              description={achievements.doloresHuerta.description}
              imageLink={achievements.doloresHuerta.imageLink}
              weekNum={achievements.doloresHuerta.weekNum}
            ></AccomplishmentOverlay>
          )}
        />
        <Route
          exact
          path="/die/:bday/grace"
          render={props => (
            <AccomplishmentOverlay
              {...props}
              name={achievements.graceHopper.name}
              age={achievements.graceHopper.age}
              description={achievements.graceHopper.description}
              imageLink={achievements.graceHopper.imageLink}
              weekNum={achievements.graceHopper.weekNum}
            ></AccomplishmentOverlay>
          )}
        />
        <Route
          exact
          path="/die/:bday/gandhi"
          render={props => (
            <AccomplishmentOverlay
              {...props}
              name={achievements.gandhi.name}
              age={achievements.gandhi.age}
              description={achievements.gandhi.description}
              imageLink={achievements.gandhi.imageLink}
              weekNum={achievements.gandhi.weekNum}
            ></AccomplishmentOverlay>
          )}
        />
        <Route
          exact
          path="/die/:bday/susan"
          render={props => (
            <AccomplishmentOverlay
              {...props}
              name={achievements.susan.name}
              age={achievements.susan.age}
              description={achievements.susan.description}
              imageLink={achievements.susan.imageLink}
              weekNum={achievements.susan.weekNum}
            ></AccomplishmentOverlay>
          )}
        />
      </Switch>
    );
  }
}

export default withRouter(DieOverlayRouter);
