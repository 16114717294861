import React, { Component } from "react";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { setToken, setUser, logOut } from "../../actions/user";
import { connect } from "react-redux";
import styles from "./HumanNavBar.module.scss";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state) => ({
  fullState: state,
});

const mapDispatchToProps = (dispatch) =>
  // okay we need to put the token in the state
  // so we can send it to authorized responses
  bindActionCreators({ setToken, setUser, logOut }, dispatch);

class HumanNavBar extends Component {
  render() {
    return (
      <div className={styles.navbarContainer}>
        <Link to="/">
          <div className={styles.homeLogo}>FailFlow</div>
        </Link>
        <div className={styles.navButtonsContainer}></div>
      </div>
    );
  }
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HumanNavBar)
);
