import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "./HNList.module.scss";
import { bindActionCreators } from "redux";
import { logOut } from "../../actions/user";
import { connect } from "react-redux";
import { postWrapper, getWrapper } from "../../helpers/fetch";
import HNListItem from "./HNListItem";

const mapStateToProps = (state) => ({
  user: state.user,
  fullState: state,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ logOut }, dispatch);

class HNTrack extends Component {
  constructor() {
    super();
    this.state = {
      linkEntry: "",
      comments: 0,
      hnpost: {},
      userLinks: [],
      hnPosts: [],
      loading: true,
    };
    this.handleType = this.handleType.bind(this);
    this.getUsersLinks = this.getUsersLinks.bind(this);
    this.removePostFromList = this.removePostFromList.bind(this);
  }

  componentDidMount() {
    this.getUsersLinks();
  }

  getParams(url) {
    var params = {};
    var parser = document.createElement("a");
    parser.href = url;
    var query = parser.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  }

  removePostFromList(post) {
    const { hnPosts } = this.state;

    const postsBefore = hnPosts;
    const postsAfter = postsBefore.filter((el) => el._id !== post._id);
    this.setState({ hnPosts: postsAfter });
  }

  async getUsersLinks() {
    const { user } = this.props;
    this.setState({ loading: true });
    // currently working on getting a users posts
    let trackers = await getWrapper("GET", "/api/getTrackers", {
      Authorization: user.token,
    });

    // reset state for adds
    this.setState({ userLinks: [], hnPosts: [] });
    let urlArray = [];

    if (trackers && trackers.hnLinks && trackers.hnLinks.length === 0) {
      this.setState({ loading: false });
      return;
    }

    trackers.hnLinks.forEach((el) => {
      let currentUserLinks = this.state.userLinks || [];
      urlArray.concat(el.link);
      this.setState({ userLinks: currentUserLinks.concat(el.link) });
      this.getAndAddPost(el.link, el._id);
    });
  }

  async getAndAddPost(link, _id) {
    const { loading } = this.state;
    const params = this.getParams(link);
    const fetchURL = `https://hacker-news.firebaseio.com/v0/item/${params.id}.json?print=pretty`;

    let post = await getWrapper("GET", fetchURL);
    post = { _id, ...post };

    this.setState({ hnPosts: this.state.hnPosts.concat(post) }, () => {
      if (loading) {
        this.setState({ loading: false });
      }
    });
  }

  logout() {
    this.props.logOut();
  }

  renderSignedIn() {
    const { user } = this.props;

    return (
      <div className={styles.container}>
        <img src={user.user_avatar_url} alt="poop" />
        <div>
          <button onClick={() => this.logout()}>Sign Out</button>
          <button onClick={() => this.getSecretMessage()}>
            Protected data
          </button>
        </div>
      </div>
    );
  }

  renderAddNew() {
    const { loading } = this.state;

    if (loading) return;

    return (
      <div className={styles.addNewParent}>
        <div className={styles.header}>Track a new post</div>
        <div className={styles.descriptionText}>
          Enter a post URL from{" "}
          <a href="https://news.ycombinator.com/news">Hacker News</a>. Links
          should look like https://news.ycombinator.com/item?id=21994381.
        </div>
        <div className={styles.addNewContainer}>
          <input
            className={styles.linkInput}
            value={this.state.linkEntry}
            onChange={this.handleType}
            placeholder="Enter HN link"
          />{" "}
          <div
            onClick={() => this.addNewTracker()}
            className={styles.submitButton}
          >
            Submit
          </div>
        </div>
      </div>
    );
  }

  handleType(event) {
    this.setState({ linkEntry: event.target.value });
  }

  async addNewTracker() {
    const { user } = this.props;
    await postWrapper(
      "POST",
      "/api/addNewTracker",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({ hnLink: this.state.linkEntry })
    );
    this.getUsersLinks();
  }

  shouldDisplayUpgrade() {
    const { user } = this.props;
    const { hnPosts } = this.state;

    if (user.accountType && user.accountType >= 1) {
      return false;
    }

    if (user.accountType && user.accountType >= 1) {
      return false;
    }

    if (hnPosts && hnPosts.length === 0) {
      return false;
    }

    return true;
  }

  userIsPro() {
    const { user } = this.props;

    return user.accountType && user.accountType >= 1;
  }

  shouldDisplayAddNew() {
    const { user } = this.props;

    if (user.accountType && user.accountType >= 1) {
      return true;
    }
    return true;
  }

  renderTrackers() {
    const { loading, hnPosts } = this.state;

    if (loading) {
      return (
        <div className={styles.trackerContainer}>
          <div>
            <div className={styles.emptyText}>Getting trackers ...</div>
            <div className={styles.loadingHolder}>
              <div className={styles.skChase}>
                <div className={styles.skChaseDot}></div>
                <div className={styles.skChaseDot}></div>
                <div className={styles.skChaseDot}></div>
                <div className={styles.skChaseDot}></div>
                <div className={styles.skChaseDot}></div>
                <div className={styles.skChaseDot}></div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (hnPosts && hnPosts.length === 0) {
      return (
        <div className={styles.trackerContainer}>
          <div>
            <div className={styles.emptyText}>
              You don't have any trackers set up yet, add one below. Or check
              out <a href="/hnitem/5ed70eb7578c21731f93d5fc">an example</a>.
            </div>

            <div className={styles.emptyImageContainer}>
              <img
                className={styles.emptyImage}
                src="https://i.imgur.com/UR5ZyjI.png"
                alt="man looking"
              ></img>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={styles.trackerContainer}>
        {hnPosts.map((el, ind) => (
          <HNListItem
            el={el}
            ind={ind}
            resetItems={this.getUsersLinks}
            key={el._id}
            removePostFromList={this.removePostFromList}
          />
        ))}
      </div>
    );
  }

  renderUpgrade() {
    const { loading } = this.state;

    if (loading) return;

    return (
      <div className={styles.addNewParent}>
        <div className={styles.header}>Need to Track More Posts?</div>
        <div className={styles.descriptionText}>
          Free plans allow for one tracker at a given time. If you would like to
          create an unlimited trackers, or need any of the other features from
          the Pro plan, check out our plans!
        </div>
        <div
          className={styles.upgradeButton}
          onClick={() => this.props.history.push("/hnupgrade")}
        >
          View Pro Plan
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={styles.fullContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.header}>
            {this.userIsPro() ? (
              <div className={styles.proBadge}>PRO</div>
            ) : null}
            Current Trackers
          </div>
          <div className={styles.descriptionText}>
            Click on an existing HN post to see high-level metrics and top-level
            comments not yet responded to by the author. Or, create a new
            tracker below.
          </div>

          {this.renderTrackers()}
          {this.shouldDisplayUpgrade()
            ? this.renderUpgrade()
            : this.renderAddNew()}
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HNTrack)
);
