const toast = (state = { show: false }, action) => {
  switch (action.type) {
    case "SHOW_TOAST":
      return { ...state, show: true, message: action.message };
    case "HIDE_TOAST":
      return { ...state, show: false };
    default:
      return state;
  }
};

export default toast;
