import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import HumanNavBar from "./HumanNavBar";
import styles from "./HumanPayForm.module.scss";
import { Helmet } from "react-helmet";
import { Elements } from "react-stripe-elements";
import HumanPayForm from "./HumanPayForm";

class HumanPay extends Component {
  renderHelmet() {
    return (
      <Helmet>
        <title>Get the Human Sounds Audio Library</title>
        <meta
          name="description"
          content="Purchasing the Human Sounds Audio Library"
        />
        <meta
          property="og:url"
          content="https://www.failflow.com/humansounds/pay"
        />
        <meta property="og:title" content="Get THE PASS" />
        <meta
          property="og:description"
          content="Purchasing the Human Sounds Audio Library"
        />
        <meta property="og:image" content="https://i.imgur.com/xpJ7LuQ.png" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/xpJ7LuQ.png"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/xpJ7LuQ.png"
        />
      </Helmet>
    );
  }

  render() {
    return (
      <div>
        {this.renderHelmet()}
        <HumanNavBar />
        <div className={styles.paymentContainer}>
          <div className={styles.header}>Checkout</div>
          <div className={styles.subHeader}>
            You're ordering the Human Sounds UX library for $5
          </div>
          <div className={styles.lineItemContainer}>
            <div className={styles.how}>
              <div className={styles.howHeader}>Human Sounds UX Library</div>
              <div>
                After checking out, you'll receive a download link that has no
                limit which will download the zipped file of all Human Sounds as
                mp3s.
              </div>
              <div className={styles.total}>Total: $5 </div>
            </div>
          </div>
          <div>Enter your payment details:</div>
          <Elements>
            <HumanPayForm />
          </Elements>
          <div className={styles.emptySpace}></div>
        </div>
      </div>
    );
  }
}

export default withRouter(HumanPay);
