import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import NavBar from "../home/NavBar.jsx";
import styles from "./PayForm.module.scss";
import { Helmet } from "react-helmet";
import { Elements } from "react-stripe-elements";
import PayForm from "./PayForm";

class Pay extends Component {
  renderHelmet() {
    return (
      <Helmet>
        <title>Get THE PASS</title>
        <meta
          name="description"
          content="How does THE PASS work and how do I get it?"
        />
        <meta property="og:url" content="https://www.failflow.com/pay" />
        <meta property="og:title" content="Get THE PASS" />
        <meta
          property="og:description"
          content="How does THE PASS work and how do I get it?"
        />
        <meta property="og:image" content="https://i.imgur.com/djWAckr.png" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/djWAckr.png"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/djWAckr.png"
        />
      </Helmet>
    );
  }

  render() {
    return (
      <div>
        {this.renderHelmet()}
        <NavBar />
        <div className={styles.paymentContainer}>
          <div className={styles.header}>Checkout</div>
          <div className={styles.subHeader}>
            You've selected <i>THE PASS</i> to be billed as a one-time payment
          </div>
          <div className={styles.lineItemContainer}>
            <div className={styles.how}>
              <div className={styles.howHeader}>
                How <i>THE PASS</i> Works
              </div>
              <div>
                The creator will release products in the future that will be
                subscription-based. With <i>THE PASS</i>, you immediately have a
                premium subscription to all products as they are released.*
              </div>
              <div className={styles.total}>Total: $20 </div>
            </div>
          </div>
          <div>Enter your payment details:</div>
          <Elements>
            <PayForm />
          </Elements>
          <div className={styles.disclaimer}>
            *More likely than not, all products released on FailFlow will be
            included, but if a product is exorbitantly costly from a compute
            perspective, like ML training or high-quality video processing -
            they may be excluded or rate-limited.
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Pay);
