import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "./ExpertDashboard.module.scss";
import { Helmet } from "react-helmet";
import Footer from "../home/Footer.jsx";
import { bindActionCreators } from "redux";
import { logOut } from "../../actions/user";
import { setExpert } from "../../actions/expert";
import { connect } from "react-redux";
import { postWrapper, getWrapper } from "../../helpers/fetch";
import stripePower from "./stripe.svg";

const mapStateToProps = (state) => ({
  user: state.user,
  fullState: state,
  expert: state.expert,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setExpert, logOut }, dispatch);

class ExportForm extends Component {
  constructor() {
    super();
    this.state = {
      expert: { theVerb: "", siteName: "", yourName: "" },
      showPay: false,
      clientSec: "",
      chargesEnabled: false,
      tempSiteName: "",
      tempYourName: "",
      tempYourBio: "",
      tempTheVerb: "consult",
      tempHelpList: "",
      tempRate: "",
      updateDone: false,
    };
  }

  async componentDidMount() {
    const { user, history } = this.props;
    console.log(this.props.fullState);
    if (Object.entries(user).length === 0) {
      history.push("/expert");
    }

    const expert = await getWrapper("GET", "/api/expert/dashboard", {
      Authorization: user.token,
    });
    console.log(expert);

    this.setState({ expert: expert.item });
    const stripeAccountInfo = await getWrapper(
      "GET",
      `/api/expert/stripeInfo/${expert.item.stripeAccountId}`,
      {
        Authorization: user.token,
      }
    );
    this.setState(
      {
        tempSiteName: expert.item.siteName,
        tempYourName: expert.item.yourName,
        tempHelpList: expert.item.helpList,
        tempRate: expert.item.rate,
        tempTheVerb: expert.item.theVerb,
        tempYourBio: expert.item.yourBio,
      },
      () => {
        console.log(this.state);
      }
    );
    console.log(stripeAccountInfo);
    console.log(stripeAccountInfo.account.charges_enabled);
    if (stripeAccountInfo.account.charges_enabled) {
      this.setState({ chargesEnabled: true });
    }
  }

  async createStripeAccountLink() {
    const { user } = this.props;
    const { expert } = this.props;
    const { stripeAccountId } = expert;
    const response = await postWrapper(
      "POST",
      "/api/createAccountLink",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({ stripeAccountId, expert })
    );
    console.log(response);
    window.location = response.accountLinks.url;
  }

  renderStripeInfo() {
    const { chargesEnabled } = this.state;
    return (
      <div>
        <div className={styles.pageHeader}>Edit or Set up Stripe Account</div>
        {!chargesEnabled ? (
          <div className={styles.invalid}>
            Not accepting charges, set up/verify your Stripe account
          </div>
        ) : null}

        <div>
          <div
            className={styles.stripeButton}
            onClick={() => this.createStripeAccountLink()}
          >
            Set Up Stripe Account
          </div>
          <img width="150px" src={stripePower} alt="powered by stripe"></img>
        </div>
      </div>
    );
  }

  async updateExpertEntry() {
    const { user } = this.props;

    const {
      expert,
      tempYourName,
      tempYourBio,
      tempTheVerb,
      tempHelpList,
      tempRate,
    } = this.state;
    const siteName = expert.siteName;
    const response = await postWrapper(
      "POST",
      "/api/updateExpert",
      {
        Authorization: user.token,
        "Content-Type": "application/json",
      },
      JSON.stringify({
        siteName,
        yourName: tempYourName,
        yourBio: tempYourBio,
        theVerb: tempTheVerb,
        helpList: tempHelpList,
        rate: tempRate,
      })
    );
    console.log(response);
    this.setState({ updateDone: true });
  }

  renderHelmet() {
    return (
      <Helmet>
        <title>Hacker News Release Dashboard</title>
        <meta
          name="description"
          content="Release, track and measure a Hacker News post or release"
        />
        <meta
          name="keywords"
          content="Hacker news, comements, release management, software engineer"
        />
        <meta property="og:url" content="https://www.failflow.com/hnrd" />
        <meta property="og:title" content="Hacker News Release Dashboard" />
        <meta
          property="og:description"
          content="Release, track and measure a Hacker News post or release"
        />
        <meta property="og:image" content="https://i.imgur.com/nWR8btF.png" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/nWR8btF.png"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/nWR8btF.png"
        />
      </Helmet>
    );
  }

  updateSiteInfo(e, key) {
    this.setState({ [key]: e.target.value });
  }

  selectVerb(verb) {
    this.setState({ tempTheVerb: verb });
  }

  logout() {
    this.props.logOut();
    this.props.history.push("/expert");
  }

  getMultiSelectClasses(verb) {
    const { tempTheVerb } = this.state;
    if (tempTheVerb === verb) {
      return `${styles.liPretty} ${styles.selectedVerb}`;
    }
    return styles.liPretty;
  }

  renderUpdateForm() {
    const { tempYourName, tempYourBio, tempHelpList, tempRate } = this.state;
    return (
      <div>
        <div className={styles.questionContainer}>
          <div className={styles.questionLabel}>Your Name</div>
          <div className={styles.questionDetails}>
            Displays your name in the introduction "Hi, I'm Shah!" Feel free to
            use you first name, first and last, nickname, or a handle
          </div>
          <input
            className={styles.simpleInput}
            placeholder="Name/Handle"
            value={tempYourName}
            maxLength="100"
            onChange={(e) => this.updateSiteInfo(e, "tempYourName")}
          ></input>
        </div>
        <div className={styles.questionContainer}>
          <div className={styles.questionLabel}>Quick Bio</div>
          <div className={styles.questionDetails}>
            Share a little bit about yourself - who you are, what you do, what
            you like! Up to 300 characters.
          </div>
          <textarea
            className={styles.simpleArea}
            placeholder="I'm a product thinker and creator. I'm the first Product Manager at Kapwing - an online video editor. I also create my own projects at FailFlow. I love building and I love helping people!"
            value={tempYourBio}
            maxLength="300"
            onChange={(e) => this.updateSiteInfo(e, "tempYourBio")}
          ></textarea>
        </div>

        <div className={styles.questionContainer}>
          <div className={styles.questionLabel}>How You'll Help</div>
          <div className={styles.questionDetails}>
            How will you help people? Will you consult, work on projects,
            mentor, tutor? This is used to update some of the instructional text
            on your page
          </div>
          <div className={styles.multiChoiceContainer}>
            <ul className={styles.ulPretty}>
              <li
                className={this.getMultiSelectClasses("Consult")}
                onClick={() => this.selectVerb("Consult")}
              >
                Consult
              </li>
              <li
                className={this.getMultiSelectClasses("Mentor")}
                onClick={() => this.selectVerb("Mentor")}
              >
                Mentor
              </li>
              <li
                className={this.getMultiSelectClasses("Tutor")}
                onClick={() => this.selectVerb("Tutor")}
              >
                Tutor
              </li>
              <li
                className={this.getMultiSelectClasses("Work")}
                onClick={() => this.selectVerb("Work")}
              >
                Work
              </li>
            </ul>{" "}
          </div>
        </div>

        <div className={styles.questionContainer}>
          <div className={styles.questionLabel}>What You Can Help With</div>
          <div className={styles.questionDetails}>
            A list of things you can help with: ex: "web design, SaaS product
            management and Super Smash Bros. tips"
          </div>
          <input
            className={`${styles.simpleInput} ${styles.simpleInputTight}`}
            placeholder="IT planning, SaaS unit economics and time management"
            value={tempHelpList}
            onChange={(e) => this.updateSiteInfo(e, "tempHelpList")}
          ></input>
        </div>
        <div className={styles.questionContainer}>
          <div className={styles.questionLabel}>Rate</div>
          <div className={styles.questionDetails}>
            How much will you charge each hour? USD
          </div>
          <input
            type="number"
            className={`${styles.simpleInput} ${styles.rate}`}
            placeholder="20.00"
            value={tempRate}
            onChange={(e) => this.updateSiteInfo(e, "tempRate")}
          ></input>
        </div>
        <div
          onClick={() => this.updateExpertEntry()}
          className={styles.continue}
        >
          Update Site
        </div>
        {this.state.updateDone ? <div>Updated!</div> : null}
      </div>
    );
  }

  render() {
    const { expert } = this.state;

    return (
      <div className={styles.all}>
        {this.renderHelmet()}
        <div className={styles.headerStrip}> </div>
        <div className={styles.containerBorder}>
          <div className={styles.contentContainer}>
            <div className={styles.pageHeader}>Expert Dashboard</div>
            {Object.entries(expert).length > 0 && expert.yourName ? (
              <div className={styles.yourName}>{expert.yourName}</div>
            ) : null}
            <div></div>
            {Object.entries(expert).length > 0 && expert.siteName ? (
              <div
                className={styles.yourPageButton}
                onClick={() => {
                  this.props.history.push(`/ex/${expert.siteName}`);
                }}
              >
                View your page
              </div>
            ) : null}
            {this.renderStripeInfo()}
            <div
              className={styles.logout}
              onClick={() => {
                this.props.logOut();
                this.props.history.push("/");
              }}
            >
              {" "}
              Log Out
            </div>
            <div className={styles.pageHeader}>Update Page</div>
            {this.renderUpdateForm()}
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ExportForm)
);
