import React, { Component } from "react";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { setToken, setUser, logOut } from "../../actions/user";
import { connect } from "react-redux";
import styles from "./HNNavBar.module.scss";
import GoogleSignIn from "../GoogleSignIn";
import { isEmpty } from "lodash";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state) => ({
  user: state.user,
  fullState: state,
});

const mapDispatchToProps = (dispatch) =>
  // okay we need to put the token in the state
  // so we can send it to authorized responses
  bindActionCreators({ setToken, setUser, logOut }, dispatch);

class NavBar extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
    };
  }

  componentDidMount() {
    // placeholder
  }

  goToSignIn() {
    this.props.history.push("/signup");
  }

  goHome() {
    this.props.history.push("/");
  }

  goToWeeks() {
    this.props.history.push("/weeksuntil");
  }

  logout() {
    this.props.logOut();
  }

  render() {
    const { user } = this.props;
    return (
      <div className={styles.navbarContainer}>
        <Link to="/hnrd">
          <div className={styles.homeLogo}>HNRD</div>
        </Link>
        <div className={styles.navButtonsContainer}>
          {/* <span className={styles.dropdown}>
            <span className={styles.navItem}>Date Calculators</span>
            <div className={styles.dropdownContent}>
              <Link to="/weeksuntil">
                <div className={styles.dropdownItem}>Weeks Until</div>
              </Link>
              <Link to="/daysuntil">
                <div className={styles.dropdownItem}>Days Until</div>
              </Link>
            </div>
          </span> */}

          {/* <span className={styles.navItem}>Projects</span>
          <span className={styles.navItem}>About</span>
          <span className={styles.navItem}>Contact</span> */}
        </div>
        {isEmpty(user) ? (
          <div className={styles.signInButton}>
            <GoogleSignIn navBar />
          </div>
        ) : (
          <div
            className={styles.signInButton}
            onClick={() => this.props.history.push("/account")}
          >
            <img
              className={styles.profPic}
              src={user.user_avatar_url}
              alt="poop"
            />
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));
