const expert = (state = {}, action) => {
  switch (action.type) {
    case "SET_EXPERT":
      return { ...state, ...action.expertData };
    case "LOG_OUT":
      return {};
    default:
      return state;
  }
};

export default expert;
