import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "./ExpertPage.module.scss";
import { Helmet } from "react-helmet";
import Footer from "../home/Footer.jsx";
import { bindActionCreators } from "redux";
import { logOut } from "../../actions/user";
import { setExpert } from "../../actions/expert";
import { connect } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";

const mapStateToProps = (state) => ({
  user: state.user,
  fullState: state,
  expert: state.expert,
  successfulPayment: false,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setExpert, logOut }, dispatch);

class ExportForm extends Component {
  constructor() {
    super();
    this.state = { expert: { theVerb: "" }, showPay: false, clientSec: "" };
    this.stripePromise = null;
    this.successfulPayment = this.successfulPayment.bind(this);
  }

  componentDidMount() {
    const siteName = this.props.match.params.siteName;
    console.log(this.props.match.params.siteName);
    console.log(this.props.fullState);
    let stripeKey = "pk_test_WKI27MGSV7vd4oBxf8zEMnFS";
    if (window && window.location.href.includes("failflow.com")) {
      stripeKey = "pk_live_WiQo0cqLPLGnGJBULP6uaPYq";
    }
    fetch(`/api/expert/page/${siteName}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        const stripePromise = loadStripe(stripeKey, {
          stripeAccount: data.item.stripeAccountId,
        });
        this.stripePromise = stripePromise;
        console.log("stripe promise ", stripePromise);
        this.setState({ expert: data.item });
      });
  }

  successfulPayment() {
    this.setState({ successfulPayment: true });
  }

  showPay() {
    const siteName = this.props.match.params.siteName;

    fetch(`/api/expert/getPaymentIntent/${siteName}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ clientSec: data.secret, showPay: true });
      });
  }

  renderHelmet() {
    return (
      <Helmet>
        <title>Hacker News Release Dashboard</title>
        <meta
          name="description"
          content="Release, track and measure a Hacker News post or release"
        />
        <meta
          name="keywords"
          content="Hacker news, comements, release management, software engineer"
        />
        <meta property="og:url" content="https://www.failflow.com/hnrd" />
        <meta property="og:title" content="Hacker News Release Dashboard" />
        <meta
          property="og:description"
          content="Release, track and measure a Hacker News post or release"
        />
        <meta property="og:image" content="https://i.imgur.com/nWR8btF.png" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/nWR8btF.png"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/nWR8btF.png"
        />
      </Helmet>
    );
  }

  render() {
    const { expert, showPay, clientSec, successfulPayment } = this.state;
    return (
      <div className={styles.all}>
        {this.renderHelmet()}
        <div className={styles.headerStrip}>
          <div
            className={styles.makeYourOwn}
            onClick={() => this.props.history.push("/expert")}
          >
            Make your own Expert page
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.contentContainer}>
            <div className={styles.splashHeader}>
              Hi, there! I'm {expert.yourName}
            </div>
            <div className={styles.bioInfo}>{expert.yourBio}</div>
            <div className={styles.availableTo}>
              I'm available to {expert.theVerb.toLowerCase()}
            </div>
            <div className={styles.helpWith}>What I can help with:</div>
            <div className={styles.helpList}>{expert.helpList}</div>

            <div className={styles.helpWith}>Rate (hourly):</div>
            <div className={styles.helpList}>${expert.rate}</div>

            {showPay && clientSec && !successfulPayment ? (
              <div className={styles.scheduleContainer}>
                <Elements stripe={this.stripePromise}>
                  <CheckoutForm
                    clientSec={clientSec}
                    successfulPayment={this.successfulPayment}
                  />
                </Elements>
              </div>
            ) : (
              <div
                className={styles.scheduleTime}
                onClick={() => this.showPay()}
              >
                Set Up Time
              </div>
            )}
            {successfulPayment ? (
              <div className={styles.success}>
                Thank you, I'll send you an email to schedule soon!
              </div>
            ) : null}
          </div>
        </div>

        <Footer></Footer>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ExportForm)
);
