import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showToast } from "../../../actions/toasts";
import { setToken, setUser } from "../../../actions/user";
import { logEvent } from "../../../helpers/amplitudeHelper";


const mapStateToProps = (state) => ({
  fullState: state,
  todos: state.todos,
  user: state.user,
  showToast: state.toast.show,
  toastMessage: state.toast.message,
});

const mapDispatchToProps = (dispatch) =>
  // okay we need to put the token in the state
  // so we can send it to authorized responses
  bindActionCreators({ showToast, setUser, setToken }, dispatch);

class NewSignIn extends Component {
  constructor() {
    super();
    this.state = {};
    window.getTheData = this.getTheData;
  }

  getTheData = (data) => {
    fetch("/api/newGoogleSignIn", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id_token: data.credential }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.props.setToken(data.token);
        this.props.setUser(data);
        this.props.history.push('/dashboard')
        logEvent("Sign In")
      });
  };

  getData(data) {
    console.log(data);
  }

  getClassName = () => {
    if (this.props.noButton) {
      return ""
    } else
      return ("g_id_signin")
  }

  render() {
    return (
      <div>
        <div>
          <div
            id="g_id_onload"
            data-client_id="381458887577-ed93492katl4u7d5becmdpvqknnbs3ti"
            data-callback="getTheData"
          ></div>
          <div
            className={this.getClassName()}
            data-type="standard"
            data-size="large"
            data-theme="outline"
            data-text="sign_in_with"
            data-shape="rectangular"
            data-logo_alignment="left"
          ></div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewSignIn));
