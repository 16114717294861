import React, { Component } from "react";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { setToken, setUser, logOut } from "../../actions/user";
import { connect } from "react-redux";
import styles from "./NavBar.module.scss";
import { MdMenu, MdClose } from "react-icons/md";

const mapStateToProps = (state) => ({
  user: state.user,
  fullState: state,
});

const mapDispatchToProps = (dispatch) =>
  // okay we need to put the token in the state
  // so we can send it to authorized responses
  bindActionCreators({ setToken, setUser, logOut }, dispatch);

class NavBar extends Component {
  constructor() {
    super();
    this.state = {
      mobileMenuOpen: false,
    };
  }

  componentDidMount() {
    // placeholder
  }

  render() {
    return (
      <div className={styles.navbarContainer}>
        {this.state.mobileMenuOpen ? (
          <div className={styles.drawer}>
            <div className={styles.drawerContentContainer}>
              <a href="/tools">
                <div className={styles.drawerItem}>Tools</div>
              </a>
              <div className={styles.spacer}></div>
              {/* <a href="/blog">
                <div className={styles.drawerItem}>Blog</div>
              </a>
              <div className={styles.spacer}></div> */}
            </div>
          </div>
        ) : null}
        <Link to="/weeksuntil">
          <div className={styles.homeLogo}>
            Weeks Until<span className={styles.byFailflow}> by FailFlow</span>
          </div>
        </Link>
        <div
          className={`${styles.navButtonsContainer} ${styles.mobileDissapear}`}
        >
          <span className={styles.navItem}>
            <Link to="/tools">Tools</Link>
          </span>
          {/* <span className={styles.navItem}>
            <a href="/blog">Blog</a>
          </span> */}
        </div>

        <div
          className={styles.mobileMenuIconFlex}
          onClick={() => {
            this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen });
          }}
        >
          {this.state.mobileMenuOpen ? (
            <MdClose className={styles.menuIcon}></MdClose>
          ) : (
            <MdMenu className={styles.menuIcon}></MdMenu>
          )}
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
