import rootReducer from "./reducers";
import { createStore, applyMiddleware } from "redux";
import { loadState, saveState } from "./localStorage.js";
import throttle from "lodash/throttle";

import logger from "redux-logger";
import thunk from "redux-thunk";

// you can manually set the default state here
// good use case is old stuff maybe? hydrating data

const configureStore = () => {
  const persistedState = loadState();
  const middlewares = [thunk];
  if (process.env.NODE_ENV !== "production") {
    middlewares.push(logger);
  }
  const store = createStore(
    rootReducer,
    persistedState,
    applyMiddleware(...middlewares)
  );

  // store.subscribe creates a listener for whenever the state changes
  store.subscribe(
    throttle(() => {
      saveState(store.getState());
    }, 1000)
  );
  return store;
};

export default configureStore;
