import React, { Component } from "react";
import styles from "./About.module.scss";
import NavBar from "./NavBar.jsx";
import Footer from "./Footer.jsx";
import { Helmet } from "react-helmet";

class Tools extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Tools - FailFlow</title>
          <meta
            name="description"
            content="Tools, utilities, and experiments by FailFlow. Date calulators and fun projects."
          />
          <meta name="keywords" content="failflow, about us" />
          <meta property="og:url" content="https://www.failflow.com" />
          <meta property="og:title" content="Tools - FailFlow" />
          <meta
            property="og:description"
            content="Tools, utilities, and experiments by FailFlow. Date calulators and fun projects."
          />
          <meta property="og:image" content="https://i.imgur.com/JRRqRyi.png" />
          <meta
            property="og:image:url"
            content="https://i.imgur.com/JRRqRyi.png"
          />
          <meta
            property="twitter:image"
            content="https://i.imgur.com/JRRqRyi.png"
          />
        </Helmet>

        <NavBar />
        <div className={styles.pageContainer}>
          <div className={styles.contentContainer}>
            <div className={styles.subTitle}>Tools</div>
            <div className={styles.title}>Date Utilities</div>
            <div className={styles.toolsGrid}>
              <a href="/weeksuntil">
                <div className={styles.toolCard}>
                  <div className={styles.toolImageCont}>
                    <img
                      className={styles.toolsImage}
                      src="https://i.imgur.com/INi6G98.jpeg"
                      alt="Weeks until calculator"
                    ></img>
                  </div>
                  <div className={styles.toolTitle}>Weeks Until</div>
                  <div className={styles.toolDesc}>
                    Calculate the number of weeks until a certain date. Or use
                    one of the holiday presets.
                  </div>
                </div>
              </a>
              <a href="/daysuntil">
                <div className={styles.toolCard}>
                  <div className={styles.toolImageCont}>
                    <img
                      className={styles.toolsImage}
                      src="https://i.imgur.com/CGxoxpX.jpeg"
                      alt="Weeks until calculator"
                    ></img>
                  </div>
                  <div className={styles.toolTitle}>Days Until</div>
                  <div className={styles.toolDesc}>
                    Calculate the number of days until a specific date or until
                    a holiday.
                  </div>
                </div>
              </a>
            </div>

            <div className={styles.title}>Experiments</div>
            <div className={styles.experimentsGrid}>
              <a className={styles.experimentsCard} href="/life">
                <div className={styles.experimentsImageCont}>
                  <img
                    className={styles.experimentsImage}
                    src="https://i.imgur.com/GNleyVE.png"
                    alt="Weeks until calculator"
                  ></img>
                </div>
                <div className={styles.experimentsTitle}>
                  Weeks Left to Live
                </div>
                <div className={styles.experimentsDesc}>
                  Calculate the number of weeks you have left to live. A
                  meditation on the finiteness of life
                </div>
              </a>
              <a className={styles.experimentsCard} href="/humansounds">
                <div className={styles.experimentsImageCont}>
                  <img
                    className={styles.experimentsImage}
                    src="https://i.imgur.com/JcsbDzk.png"
                    alt="Weeks until calculator"
                  ></img>
                </div>
                <div className={styles.experimentsTitle}>Human Sounds</div>
                <div className={styles.experimentsDesc}>
                  A UX sound library for a more human experience
                </div>
              </a>
              <a className={styles.experimentsCard} href="/somethingnew">
                <div className={styles.experimentsImageCont}>
                  <img
                    className={styles.experimentsImage}
                    src="https://i.imgur.com/GxfSLY1.jpeg"
                    alt="Weeks until calculator"
                  ></img>
                </div>
                <div className={styles.experimentsTitle}>
                  Who's Building Something New?
                </div>
                <div className={styles.experimentsDesc}>
                  Scrapes Twitter for bios that include "building something
                  new," find and fund the next unicorn
                </div>
              </a>
              <a className={styles.experimentsCard} href="/hnrd">
                <div className={styles.experimentsImageCont}>
                  <img
                    className={styles.experimentsImage}
                    src="https://i.imgur.com/Qu0MTjq.jpeg"
                    alt="Weeks until calculator"
                  ></img>
                </div>
                <div className={styles.experimentsTitle}>
                  Hacker News Release Dashboard
                </div>
                <div className={styles.experimentsDesc}>
                  Track your HN posts and see how many comments are left to
                  respond to
                </div>
              </a>
              <a className={styles.experimentsCard} href="/bestgame">
                <div className={styles.experimentsImageCont}>
                  <img
                    className={styles.experimentsImage}
                    src="https://i.imgur.com/qKVmAzl.png"
                    alt="Weeks until calculator"
                  ></img>
                </div>
                <div className={styles.experimentsTitle}>Five-Minute RPG</div>
                <div className={styles.experimentsDesc}>
                  A Pokemon-like RPG that you can beat in 5 minutes!
                </div>
              </a>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Tools;
