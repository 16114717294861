import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "./ExpertNavBar.module.scss";
import { bindActionCreators } from "redux";
import { setUser, logOut } from "../../actions/user";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  user: state.user,
  fullState: state,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setUser, logOut }, dispatch);

class ExportHome extends Component {
  goTo = (route) => {
    this.props.history.push(route);
  };
  render() {
    return (
      <div className={styles.yellowStrip}>
        <div className={styles.navContentContainer}>
          <div onClick={() => this.goTo("/expert")} className={styles.home}>
            Expert
          </div>
          <div className={styles.rightSide}>
            <div
              onClick={() => this.goTo("/expert/pricing")}
              className={styles.navItem}
            >
              Pricing
            </div>
            <div
              onClick={() => this.goTo("/expert/about")}
              className={styles.navItem}
            >
              About
            </div>
            <div onClick={() => this.goTo("/")} className={styles.navItem}>
              What's FailFlow?
            </div>
          </div>
          <div></div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ExportHome)
);
