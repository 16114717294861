import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "./WeeksInput.module.scss";
import WeeksUntilNavBar from "../home/WeeksUntilNavBar.jsx";
import { Helmet } from "react-helmet";
import Footer from "../home/Footer.jsx";

class OneWeek extends Component {
  constructor() {
    super();
    this.state = {
      nextWeekStr: "",
    };
  }

  componentDidMount() {
    let today = new Date();
    let nextWeek = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
    let options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    let nextWeekStr = nextWeek.toLocaleDateString("en-US", options);

    this.setState({ nextWeekStr });
  }

  copyLink() {
    /* Get the text field */
    var copyText = document.getElementById("shareLink");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");
  }

  renderWeeksUntil() {
    const { nextWeekStr } = this.state;
    return (
      <div className={styles.enterDateContainer}>
        <div className={styles.entryContent}>
          <div className={styles.header}>One Week from today will be</div>
          <div className={styles.weeksTitle}>{nextWeekStr}</div>
          <div className={styles.helpText}>
            This page will recalcalculate with every visit, feel free to
            bookmark or share the link below.
          </div>
          <div>
            <input
              className={styles.copyLinkInput}
              value={window.location}
              id="shareLink"
              readOnly
              onClick={() => this.copyLink()}
            ></input>
            <div className={styles.copyButton} onClick={() => this.copyLink()}>
              Copy Link
            </div>
          </div>
          <div className={styles.weeksAwayBlock}>
            <div className={styles.holidayContainer}>
              <a href="/weeksuntil/endofyear">
                <div className={styles.holiday}>
                  Weeks until End of the Year?
                </div>
              </a>
              <a href="/weeksuntil/christmas">
                <div className={styles.holiday}>Weeks until Christmas?</div>
              </a>
              <a href="/weeksuntil/thanksgiving">
                <div className={styles.holiday}>Weeks until Thanksgiving?</div>
              </a>
            </div>
          </div>
          <div>
            <a href="/weeksuntil/3-weeks-from-today">
              <div className={styles.weeksFromTodayLink}>
                What day is 3 weeks from today?
              </div>
            </a>
            <a href="/weeksuntil/2-weeks-from-today">
              <div className={styles.weeksFromTodayLink}>
                What day is 2 weeks from today?
              </div>
            </a>
            <a href="/weeksuntil/1-week-from-today">
              <div className={styles.weeksFromTodayLink}>
                What day is a week from today?
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  }

  renderHelmet() {
    return (
      <Helmet>
        <title>What day is one week from now?</title>
        <meta
          name="description"
          content="Calculate the day one week from now"
        />
        <meta
          name="keywords"
          content="weeks until, weeks till, weeks til, calculate weeks"
        />
        <meta
          property="og:url"
          content="https://www.failflow.com/weeksuntil/1-week-from-today"
        />
        <meta property="og:title" content="How many weeks until" />
        <meta
          property="og:description"
          content="What day is one week from now?"
        />
        <meta property="og:image" content="https://i.imgur.com/HmyUCpi.png" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/HmyUCpi.png"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/HmyUCpi.png"
        />
      </Helmet>
    );
  }

  render() {
    return (
      <div>
        {this.renderHelmet()}
        <WeeksUntilNavBar />
        {this.renderWeeksUntil()}
        <Footer></Footer>
      </div>
    );
  }
}

export default withRouter(OneWeek);
