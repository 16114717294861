import { Fragment, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Dialog, Transition } from '@headlessui/react'
import { Bars3CenterLeftIcon, BookOpenIcon, CalendarDaysIcon, HomeIcon, XMarkIcon } from '@heroicons/react/24/outline'
import {
  ChevronRightIcon,
  CheckCircleIcon,
} from '@heroicons/react/20/solid'
import { Helmet } from "react-helmet";
import {
  ChevronLeftIcon,
} from '@heroicons/react/20/solid'
import { logEvent } from "../../../helpers/amplitudeHelper";
import { setUser, logOut } from "../../../actions/user";
import ReactGA from "react-ga";
import Drift from "react-driftjs";

let october = [
  { date: '2022-09-25' },
  { date: '2022-09-26' },
  { date: '2022-09-27' },
  { date: '2022-09-28' },
  { date: '2022-09-29' },
  { date: '2022-09-30' },
  { date: '2022-10-01', isCurrentMonth: true },
  { date: '2022-10-02', isCurrentMonth: true },
  { date: '2022-10-03', isCurrentMonth: true },
  { date: '2022-10-04', isCurrentMonth: true },
  { date: '2022-10-02', isCurrentMonth: true },
  { date: '2022-10-06', isCurrentMonth: true },
  { date: '2022-10-07', isCurrentMonth: true },
  { date: '2022-10-08', isCurrentMonth: true },
  { date: '2022-10-09', isCurrentMonth: true },
  { date: '2022-10-10', isCurrentMonth: true },
  { date: '2022-10-11', isCurrentMonth: true },
  { date: '2022-10-12', isCurrentMonth: true },
  { date: '2022-10-13', isCurrentMonth: true },
  { date: '2022-10-14', isCurrentMonth: true },
  { date: '2022-10-15', isCurrentMonth: true },
  { date: '2022-10-16', isCurrentMonth: true },
  { date: '2022-10-17', isCurrentMonth: true },
  { date: '2022-10-18', isCurrentMonth: true },
  { date: '2022-10-19', isCurrentMonth: true },
  { date: '2022-10-20', isCurrentMonth: true },
  { date: '2022-10-21', isCurrentMonth: true },
  { date: '2022-10-22', isCurrentMonth: true },
  { date: '2022-10-23', isCurrentMonth: true },
  { date: '2022-10-24', isCurrentMonth: true },
  { date: '2022-10-25', isCurrentMonth: true },
  { date: '2022-10-26', isCurrentMonth: true },
  { date: '2022-10-27', isCurrentMonth: true },
  { date: '2022-10-28', isCurrentMonth: true },
  { date: '2022-10-29', isCurrentMonth: true },
  { date: '2022-10-30', isCurrentMonth: true },
  { date: '2022-10-31', isCurrentMonth: true },
  { date: '2022-11-01' },
  { date: '2022-11-02' },
  { date: '2022-11-03' },
  { date: '2022-11-04' },
  { date: '2022-11-02' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const stripePaymentLink = 'https://buy.stripe.com/00gcNO5Xs6JYeWY289?prefilled_email='

export default function Example(props) {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()


  const [mainContent, setMainContent] = useState('dashboard')
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [mortalityDone, setMortalityDone] = useState(false)
  const [gratitudeDone, setGratitudeDone] = useState(false)

  const [gratitudeOne, setGratitudeOne] = useState("")
  const [gratitudeTwo, setGratitudeTwo] = useState("")
  const [gratitudeThree, setGratitudeThree] = useState("")

  const [exercisesData, setExercisesData] = useState([])
  const [gratitudeData, setGratitudeData] = useState([])
  const [gratitudeIndex, setGratitudeIndex] = useState(null)


  const [days, setDays] = useState([
    { date: '2023-03-26',  },
    { date: '2023-03-27',  },
    { date: '2023-03-28',  },
    { date: '2023-03-29',  },
    { date: '2023-03-30',  },
    { date: '2023-03-31',  },
    { date: '2023-04-01', isCurrentMonth: true },
    { date: '2023-04-02', isCurrentMonth: true },
    { date: '2023-04-03', isCurrentMonth: true },
    { date: '2023-04-04', isCurrentMonth: true },
    { date: '2023-04-05', isCurrentMonth: true },
    { date: '2023-04-06', isCurrentMonth: true },
    { date: '2023-04-07', isCurrentMonth: true },
    { date: '2023-04-08', isCurrentMonth: true },
    { date: '2023-04-09', isCurrentMonth: true },
    { date: '2023-04-10', isCurrentMonth: true },
    { date: '2023-04-11', isCurrentMonth: true },
    { date: '2023-04-12', isCurrentMonth: true },
    { date: '2023-04-13', isCurrentMonth: true },
    { date: '2023-04-14', isCurrentMonth: true },
    { date: '2023-04-15', isCurrentMonth: true },
    { date: '2023-04-16', isCurrentMonth: true },
    { date: '2023-04-17', isCurrentMonth: true },
    { date: '2023-04-18', isCurrentMonth: true },
    { date: '2023-04-19', isCurrentMonth: true },
    { date: '2023-04-20', isCurrentMonth: true },
    { date: '2023-04-21', isCurrentMonth: true },
    { date: '2023-04-22', isCurrentMonth: true },
    { date: '2023-04-23', isCurrentMonth: true },
    { date: '2023-04-24', isCurrentMonth: true },
    { date: '2023-04-25', isCurrentMonth: true },
    { date: '2023-04-26', isCurrentMonth: true },
    { date: '2023-04-27', isCurrentMonth: true },
    { date: '2023-04-28', isCurrentMonth: true},
    { date: '2023-04-29', isCurrentMonth: true},
    { date: '2023-04-30', isCurrentMonth: true},
    { date: '2023-05-01', },
    { date: '2023-05-02', },
    { date: '2023-05-03', },
    { date: '2023-05-04', },
    { date: '2023-05-05', },
    { date: '2023-05-06', },


  ])

  useEffect(() => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    fetch("/api/getExercise", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: user.token,
      },
      body: JSON.stringify({ date: today }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.mortality) {
          setMortalityDone(true)
        }

        if (data.gratitude) {
          setGratitudeDone(true)
        }

        if (data.gratitudeTwo !== "") {
          setGratitudeTwo(data.gratitudeTwo)
        }

        if (data.gratitudeOne !== "") {
          setGratitudeOne(data.gratitudeOne)
        }

        if (data.gratitudeThree) {
          setGratitudeThree(data.gratitudeThree)
        }
      });

  }, [user.token]);

  useEffect(() => {
    fetch("/api/getMonthExercises", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: user.token,
      },
      body: JSON.stringify({}),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.hasOwnProperty('message')) {
          setExercisesData(data.message)
          data.message.forEach(updateCal)
          const gratitudeArray = data.message.filter(day => day.gratitudeOne || day.gratitudeTwo || day.gratitudeThree)
          setGratitudeIndex(gratitudeArray.length - 1)
          setGratitudeData(gratitudeArray)
        }
      });

  }, [user.token]);

  //TODO - finish user refresh, solves upgrade problem, solves birthday problem

  useEffect(() => {
    fetch("/api/refreshUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: user.token,
      },
      body: JSON.stringify({}),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.message === "INVALID_TOKEN") {
          dispatch(logOut())
          props.history.push("/")
        } else {
          let cloneData = { ...data }
          delete cloneData._id
          dispatch(setUser(cloneData))
        }
        // make a clone, i want to remove _id before dispatch

      });

  }, [user.token]);

  const updateCal = (item) => {
    const newDays = days.map((day) => {
      let newDay = day
      if (day.isCurrentMonth) {
        if (newDay.date === item.date.slice(0, 10)) {
          newDay.done = true
        }
      }
      return newDay
    })

    setDays(newDays)
  }

  const didMortality = (user, repeat = false) => {
    ReactGA.event({
      category: "Navigation",
      action: "Visited Mortality",
    });
    logEvent("Visited Mortality")

    function weeksBetween(date1, date2) {
      var ONE_WEEK = 1000 * 60 * 60 * 24 * 7;
      var date1_ms = date1.getTime();
      var date2_ms = date2.getTime();
      var difference_ms = Math.abs(date1_ms - date2_ms);
      return Math.floor(difference_ms / ONE_WEEK);
    }

    const today = new Date()
    today.setHours(0, 0, 0, 0)

    fetch("/api/updateExercise", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: user.token,
      },
      body: JSON.stringify({ date: today, mortality: true }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // this.props.setToken(data.token);
        // this.props.setUser(data);
      });

    if ('year' in user) {
      const { month, day, year } = user;

      let birthday = new Date(year, month, day);

      const passed = weeksBetween(birthday, new Date());
      props.history.push("/mortality/" + passed);
    } else {
      props.history.push("/mortality");
    }

  }

  const didGratitude = (user, repeat = false) => {
    ReactGA.event({
      category: "Navigation",
      action: "Did Gratitude",
    });
    logEvent("Did Gratitude")

    const today = new Date()
    today.setHours(0, 0, 0, 0)
    setGratitudeDone(true)
    // update the done state, needed for immediate calendar update, will eventually fetch from db
    let newDays = days.map(day => {
      const todayDate = new Date().toISOString().substring(0, 10)
      if (day.date === todayDate) {
        let newDay = { ...day, done: true }
        return newDay

      } else {
        return day
      }
    })
    setDays(newDays)

    fetch("/api/updateExercise", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: user.token,
      },
      body: JSON.stringify({ date: today, gratitude: true, gratitudeOne, gratitudeTwo, gratitudeThree }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // this.props.setToken(data.token);
        // this.props.setUser(data);
      });
    setMainContent("dashboard")
  }

  const renderDashboard = () => {
    return (
      <div className=''>
        <Drift appId="pbn5kccpcwrr"></Drift>
        <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
          <div className="min-w-0 flex-1">
            <h1 className="w-fit text-4xl font-bold tracking-tight bg-gradient-to-r from-blue-500 to-indigo-500 bg-clip-text text-transparent ">
              {(new Date()).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
            </h1>
          </div>

        </div>
        {/* Pinned projects */}
        <div className="mt-6 px-4 sm:px-6 lg:px-8">
          <h2 className="mb-5 text-2xl font-bold tracking-tight ">
            Today's Activities
          </h2>
          <div>
            {/* List of exercises */}
            <div className="overflow-hidden rounded-lg bg-white shadow max-w-xl mb-5">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    {mortalityDone ?
                      <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" /> :
                      <CheckCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                    }
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="truncate text-sm font-medium text-gray-500">
                        Weeks Left to Live
                      </dt>
                      <dd>
                        <div className="text-lg font-medium text-gray-900">
                          Comtemplate your mortality
                        </div>
                      </dd>
                    </dl>
                  </div>
                  {mortalityDone ?
                    <div
                      className='inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 hover:cursor-pointer'
                      onClick={() => {
                        didMortality(user, true)
                      }}>
                      View Again
                    </div> :
                    <div
                      className='inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700  hover:cursor-pointer'
                      onClick={() => {
                        didMortality(user)
                      }}>
                      Start Exercise
                    </div>}
                </div>
              </div>
            </div>

            <div className="overflow-hidden rounded-lg bg-white shadow max-w-xl mb-5">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    {gratitudeDone ?
                      <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" /> :
                      <CheckCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                    }
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="truncate text-sm font-medium text-gray-500">
                        Gratitude Journal
                      </dt>
                      <dd>
                        <div className="text-lg font-medium text-gray-900">
                          List Three Things You Appreciated Today
                        </div>
                      </dd>
                    </dl>
                  </div>
                  {gratitudeDone ? <div
                    className='inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 hover:cursor-pointer'
                    onClick={() => { setMainContent("gratitude") }}
                  >
                    Edit Log
                  </div> : <div
                    className='inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 hover:cursor-pointer'
                    onClick={() => { setMainContent("gratitude") }}
                  >
                    Start Exercise
                  </div>}
                </div>
              </div>
            </div>

            {user.accountType !== 1 ?
              <div className="mt-5 p-5 rounded-lg bg-sky-200 text-base font-semibold shadow max-w-xl">
                <div>
                  More activites coming with our launch later this year.  Subscribe now and lock in an early bird price before launch.
                </div>
                <div className='flex justify-end'>
                  <div
                    onClick={() => {
                      // TODO add analytics for amplitude
                      ReactGA.event({
                        category: "Navigation",
                        action: "Visited Checkout",
                      });
                      window.location = stripePaymentLink + user.user_email;
                    }}
                    className='mr-0 inline-flex justify-end items-center rounded-md font-semibold px-4 py-2 text-sm text-white shadow-sm bg-gradient-to-r from-indigo-500  to-pink-500 hover:from-indigo-600  hover:to-pink-600  hover:cursor-pointer'>
                    Upgrade now
                  </div>
                </div>
              </div> :
              <div className="mt-5 p-5 rounded-lg bg-sky-200 text-base font-semibold shadow max-w-xl">
                <div>
                  More activites coming with our launch later this year!
                </div>

              </div>}



          </div>


          {/* <h2 className="mt-5 mb-2 text-2xl font-bold tracking-tight ">
            Activity Log
          </h2>
          <div className='mb-2 max-w-xl text-sm font-semibold text-gray-700'>The benefits of practicing gratitude come with consistency.  Try conducting a practice on a daily basis and see how you feel. </div>

 */}

          {/* Calendar example */}
          {/* <div>
            <div className="text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9 max-w-xl">
              <div className="flex items-center text-gray-900">
                <button
                  type="button"
                  className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Previous month</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <div className="flex-auto font-semibold">April</div>
                <button
                  type="button"
                  className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Next month</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
                <div>S</div>
                <div>M</div>
                <div>T</div>
                <div>W</div>
                <div>T</div>
                <div>F</div>
                <div>S</div>
              </div>
              <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200 mb-10">
                {days.map((day, dayIdx) =>
                (
                  <div
                    key={day.date}
                    className={classNames(
                      'py-1.5 hover:bg-gray-100 focus:z-10',
                      day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                      (day.isSelected || day.isToday) && 'font-semibold',
                      day.isSelected && 'text-white',
                      !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                      !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-400',
                      day.isToday && !day.isSelected && 'text-indigo-600',
                      dayIdx === 0 && 'rounded-tl-lg',
                      dayIdx === 6 && 'rounded-tr-lg',
                      dayIdx === days.length - 7 && 'rounded-bl-lg',
                      dayIdx === days.length - 1 && 'rounded-br-lg',
                    )}
                  >
                    <div
                      className={classNames(
                        'flex',
                        'mx-auto',
                        'h-8',
                        'w-8',
                        'rounded-full',
                        !!day.done && 'bg-blue-600'


                      )}>

                      <time
                        dateTime={day.date}
                        className={classNames(
                          'mx-auto flex items-center justify-center rounded-full',
                          day.isSelected && day.isToday && 'bg-indigo-600',
                          day.isSelected && !day.isToday && 'bg-gray-900',
                          !!day.done && 'text-white'
                        )}
                      >
                        {day.date.split('-').pop().replace(/^0/, '')}
                      </time>
                    </div>

                  </div>
                ))}
              </div>
            </div>
          </div> */}
        </div>
      </div>
    )
  }

  const renderAccount = () => {
    return (
      <div className=''>
        <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
          <div className="min-w-0 flex-1">
            <h1 className="w-fit text-4xl font-bold tracking-tight bg-gradient-to-r from-blue-500 to-indigo-500 bg-clip-text text-transparent ">
              Account Settings
            </h1>
          </div>

        </div>
        <div className="mt-6 px-4 sm:px-6 lg:px-8">
          <div>
            <div className="">
              <div className="">
                <h2 className="mb-2 text-xl font-bold tracking-tight flex">
                  Manage Subscriptions
                </h2>
                <p>Only available if you've purchased a subscription</p>
                <a href='https://billing.stripe.com/p/login/00gcOT64tfpk8Rq288'>
                  <div className='max-w-lg w-100'>
                    <div className='inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 mt-5 mb-16 text-sm font-medium text-white shadow-sm hover:bg-blue-700 hover:cursor-pointer'
                    >
                      Manage Subscriptions on Stripe</div>
                  </div>
                </a>

                <h2 className="mb-2 text-xl font-bold tracking-tight flex">
                  Log Out
                </h2>
                <div className='max-w-lg w-100'>
                  <div
                    onClick={() => {
                      dispatch(logOut())
                      props.history.push("/")
                    }}
                    className='inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 mt-5 mb-16 text-sm font-medium text-white shadow-sm hover:bg-blue-700 hover:cursor-pointer'
                  >
                    Log Out</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderGratitudeJournal = () => {
    return (
      <div className=''>
        <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
          <div className="min-w-0 flex-1">
            <h1 className="w-fit text-4xl font-bold tracking-tight bg-gradient-to-r from-blue-500 to-indigo-500 bg-clip-text text-transparent ">
              Gratitude Journal
            </h1>
            <div className='text-gray-600 font-semibold mt-1'>Log three items that you are grateful for today.</div>
          </div>


        </div>
        <div className="mt-6 px-4 sm:px-6 lg:px-8">
          <h2 className="mb-5 text-2xl font-bold tracking-tight flex items-center justify-between max-w-lg">
            <ChevronLeftIcon
              className={classNames('h-6', 'text-gray-400', gratitudeData.length ? 'hover:cursor-pointer hover:text-gray-500' : null,)}
              onClick={() => {
                if (gratitudeData.length) {
                  setGratitudeIndex(gratitudeData.length - 1)
                  setMainContent('previous')
                }
              }}
            ></ChevronLeftIcon>
            <span className='mx-4 '>Today</span>
            <ChevronRightIcon className='h-6 text-gray-400 '></ChevronRightIcon>
          </h2>

          <div>
            <div className="">
              <div className="">
                <h2 className="mb-2 text-xl font-bold tracking-tight flex">
                  One</h2>
                <textarea
                  rows={3}
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={gratitudeOne}
                  onChange={e => setGratitudeOne(e.target.value)}
                />
                <p className="mt-2 text-sm text-gray-500">Any jazzy music you liked today? Maybe an interaction with someone? </p>
              </div>
            </div>

            <div className="mt-5">
              <div className="">
                <h2 className="mb-2 text-xl font-bold tracking-tight flex">
                  Two</h2>
                <textarea
                  rows={3}
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={gratitudeTwo}
                  onChange={e => setGratitudeTwo(e.target.value)}
                />
                <p className="mt-2 text-sm text-gray-500">Anything out of the norm happen? Maybe even a funny TikTok?</p>
              </div>
            </div>

            <div className="mt-5">
              <div className="">
                <h2 className="mb-2 text-xl font-bold tracking-tight flex">
                  Three</h2>
                <textarea
                  rows={3}
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={gratitudeThree}
                  onChange={e => setGratitudeThree(e.target.value)}
                />
                <p className="mt-2 text-sm text-gray-500">
                  Liking the weather? Any good food?
                </p>
              </div>
            </div>
            <div className='max-w-lg w-100'>
              <div className='float-right inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 mt-5 mb-16 text-sm font-medium text-white shadow-sm hover:bg-blue-700 hover:cursor-pointer'
                onClick={() => { didGratitude(user) }}
              >
                Done</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderGratitudePrevious = () => {
    return (
      <div className=''>
        <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
          <div className="min-w-0 flex-1">
            <h1 className="w-fit text-4xl font-bold tracking-tight bg-gradient-to-r from-blue-500 to-indigo-500 bg-clip-text text-transparent ">
              Gratitude Journal
            </h1>
            <div className='text-gray-600 font-semibold mt-1'>Log three items that you are grateful for today.</div>
          </div>

        </div>
        <div className="mt-6 px-4 sm:px-6 lg:px-8">
          <h2 className="mb-5 text-2xl font-bold tracking-tight flex items-center justify-between max-w-lg">
            <ChevronLeftIcon
              className={classNames(
                'h-6',
                'text-gray-400',
                gratitudeIndex === 0 ? null : 'hover:cursor-pointer',
                gratitudeIndex === 0 ? null : 'hover:text-gray-500'
              )}
              onClick={() => {
                if (gratitudeIndex > 0) {
                  setGratitudeIndex(gratitudeIndex - 1)
                }
                return
              }}
            >
            </ChevronLeftIcon>
            <span className='mx-4 '>
              {(new Date(gratitudeData[gratitudeIndex].date)).toLocaleString('en-US', {
                weekday: 'long', // "Sat"
                month: 'long', // "June"
                day: '2-digit', // "01"
                year: 'numeric' // "2019"
              })}
            </span>
            <ChevronRightIcon
              className={classNames(
                'h-6',
                'text-gray-400',
                gratitudeIndex === gratitudeData.length ? null : 'hover:cursor-pointer',
                gratitudeIndex === gratitudeData.length ? null : 'hover:text-gray-500'
              )}
              onClick={() => {
                if (gratitudeIndex < gratitudeData.length - 1) {
                  setGratitudeIndex(gratitudeIndex + 1)
                } else if (gratitudeIndex === gratitudeData.length - 1) {
                  setMainContent('gratitude')
                }
                return
              }}
            ></ChevronRightIcon>
          </h2>

          <div>
            <div className="">
              <div className="">

                <div className="overflow-hidden max-w-lg rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                  <h2 className="mb-2 text-xl font-bold tracking-tight flex">
                    One</h2>
                  {gratitudeData[gratitudeIndex].gratitude ? gratitudeData[gratitudeIndex].gratitudeOne : null}
                </div>
              </div>
            </div>

            <div className="mt-5">
              <div className="">

                <div className="overflow-hidden max-w-lg rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                  <h2 className="mb-2 text-xl font-bold tracking-tight flex">
                    Two</h2>
                  {gratitudeData[gratitudeIndex].gratitude ? gratitudeData[gratitudeIndex].gratitudeTwo : null}
                </div>
              </div>
            </div>

            <div className="mt-5">
              <div className="">

                <div className="overflow-hidden max-w-lg rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                  <h2 className="mb-2 text-xl font-bold tracking-tight flex">
                    Three</h2>
                  {gratitudeData[gratitudeIndex].gratitude ? gratitudeData[gratitudeIndex].gratitudeThree : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }



  return (
    <>
      <Helmet>
        <title>FailFlow - You're going to die.  Make the most of it.</title>
        <meta
          name="description"
          content="Daily activities and reminders to be more mindful.  Create a private gratitude journal and use mortality reminders."
        />
        <meta
          name="keywords"
          content="failflow, weeks until you die, gratitude journal"
        />
        <meta property="og:url" content="https://www.failflow.com" />
        <meta property="og:title" content="FailFlow" />
        <meta
          property="og:description"
          content="Daily activities and reminders to be more mindful.  Create a private gratitude journal and use mortality reminders."
        />
        <meta property="og:image" content="https://i.imgur.com/JRRqRyi.png" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/JRRqRyi.png"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/JRRqRyi.png"
        />
      </Helmet>
      <div className="min-h-screen bg-gray-100">

        {/* Mobile sidebar, all of Transition  */}
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full "
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <div className='text-base font-bold'>shahahmed@gmail.com</div>
                  </div>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto">
                    <nav className="px-2">
                      <div className="space-y-1">
                        <div
                          onClick={() => {
                            setMainContent("dashboard")
                            setSidebarOpen(false)
                          }}
                          className={classNames(
                            mainContent === "dashboard"
                              ? 'bg-gray-100 text-gray-600'
                              : 'text-gray-600 hover:text-gray-600 hover:bg-gray-50',
                            'group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md hover:cursor-pointer'
                          )}
                        >
                          <HomeIcon
                            className={classNames(
                              mainContent === "dashboard"
                                ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                              'mr-3 flex-shrink-0 h-6 w-6')}
                            aria-hidden="true"
                          />
                          Home
                        </div>
                        <div
                          onClick={() => {
                            didMortality(user)
                            setSidebarOpen(false)
                          }}
                          className={classNames(
                            mainContent === "weeks"
                              ? 'bg-gray-100 text-gray-200'
                              : 'text-gray-600 hover:text-gray-600 hover:bg-gray-50',
                            'group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md hover:cursor-pointer'
                          )}
                        >
                          <CalendarDaysIcon
                            className={classNames(
                              mainContent === "weeks"
                                ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                              'mr-3 flex-shrink-0 h-6 w-6',
                              'hover:cursor-pointer'
                            )}
                            aria-hidden="true"
                          />
                          Weeks Left
                        </div>

                        <div
                          onClick={() => {
                            setMainContent("gratitude")
                            setSidebarOpen(false)
                          }}
                          className={classNames(
                            mainContent === "gratitude"
                              ? 'bg-gray-100 text-gray-600'
                              : 'text-gray-600 hover:text-gray-600 hover:bg-gray-50',
                            'group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md hover:cursor-pointer'
                          )}
                        >
                          <BookOpenIcon
                            className={classNames(
                              mainContent === "gratitude"
                                ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                              'mr-3 flex-shrink-0 h-6 w-6',

                            )}
                            aria-hidden="true"
                          />
                          Gratitude Journal
                        </div>
                      </div>

                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root >

        {/* Static sidebar for desktop */}
        < div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col lg:border-r lg:border-gray-200 lg:bg-gray-900 lg:pt-5 lg:pb-4" >

          {/* Sidebar component, swap this element with another sidebar if you like */}
          < div className="flex h-0 flex-1 flex-col overflow-y-auto" >
            {/* Profile pic section */}
            <div className="relative inline-block px-3 text-left" >
              <div>
                <div
                  onClick={() => { setMainContent('account') }}
                  className="group w-full rounded-md px-3.5 py-2 text-left text-sm font-medium text-gray-300 hover:bg-gray-800 hover:cursor-pointer">
                  <span className="flex w-full items-center justify-between">
                    <span className="flex min-w-0 items-center justify-between space-x-3">
                      <img
                        className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
                        src={user.user_avatar_url}
                        referrerPolicy="no-referrer"
                        alt=""
                      />
                      <span className="flex min-w-0 flex-1 flex-col">
                        <span className="truncate text-sm font-medium text-gray-300">{user.user_first_name}</span>
                        <span className="truncate text-xs text-gray-500">{user.user_email}</span>
                      </span>
                    </span>
                  </span>
                </div>
              </div>

            </div >

            {/* Navigation */}
            <nav className="mt-6 px-3" >
              <div className="space-y-1">
                <a
                  href={'/dashboard'}
                  className={classNames(
                    mainContent === 'dashboard' ? 'bg-gray-800 text-gray-300' : 'text-gray-200 hover:text-gray-300 hover:bg-gray-800',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                  )}
                >
                  <HomeIcon
                    className={classNames(
                      mainContent === 'dashboard' ? 'text-gray-200' : 'text-gray-100 group-hover:text-gray-200',
                      'mr-3 flex-shrink-0 h-6 w-6'
                    )}
                    aria-hidden="true"
                  />
                  Home
                </a>

                <div
                  onClick={() => { didMortality(user) }}
                  className={classNames(
                    mainContent === "week" ? 'bg-gray-800 text-gray-300' : 'text-gray-200 hover:text-gray-300 hover:bg-gray-800',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md hover:cursor-pointer'
                  )}
                >

                  <CalendarDaysIcon
                    className={classNames(
                      mainContent === "mainContent === 'home'" ? 'text-gray-200' : 'text-gray-100 group-hover:text-gray-200',
                      'mr-3 flex-shrink-0 h-6 w-6 hover:cursor-pointer',
                    )}
                    aria-hidden="true"
                  />
                  Weeks Left
                </div>


                <div
                  onClick={() => { setMainContent("gratitude") }}
                  className={classNames(
                    mainContent === "gratitude" ? 'bg-gray-800 text-gray-300' : 'text-gray-200 hover:text-gray-300 hover:bg-gray-800',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md hover:cursor-pointer'
                  )}
                >
                  <BookOpenIcon
                    className={classNames(
                      mainContent === "gratitude" ? 'text-gray-200' : 'text-gray-100 group-hover:text-gray-200',
                      'mr-3 flex-shrink-0 h-6 w-6 hover:cursor-pointer',
                    )}
                    aria-hidden="true"
                  />
                  Gratitude Journal
                </div>
              </div>{
                user.accountType !== 1 &&
                <div className="mt-5 mx-auto">
                  <div
                    onClick={() => {
                      // TODO add analytics for amplitude
                      ReactGA.event({
                        category: "Navigation",
                        action: "Visited Checkout",
                      });
                      window.location = stripePaymentLink + user.user_email;
                    }}
                    className=' mx-auto w-fit rounded-md font-semibold px-4 py-2 text-sm text-white shadow-sm bg-gradient-to-r from-indigo-500  to-pink-500 hover:from-indigo-600  hover:to-pink-600  hover:cursor-pointer'>
                    Upgrade now
                  </div>
                </div>
              }

            </nav >
          </div >
        </div >
        {/* Main column */}
        < div className="flex flex-col lg:pl-64" >
          {/* Search header */}
          < div className="sticky top-0 z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white lg:hidden" >
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-500  lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3CenterLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex flex-1 justify-between px-4 sm:px-6 lg:px-8">

              <div className="flex items-center">
                {/* Profile mobile top */}
                <div className="relative ml-3">
                  <div>
                    <div className="flex max-w-xs items-center rounded-full bg-white text-sm ">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={user.user_avatar_url}
                        referrerPolicy="no-referrer"
                        alt=""
                      />
                      <div className='ml-2 font-bold'>
                        <div>{user.user_first_name}</div>
                        <div className='text-xs text-gray-600'>{user.user_email}</div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div >
          <main className="flex-1">
            {mainContent === "dashboard" ? renderDashboard() :
              mainContent === "gratitude" ? renderGratitudeJournal() :
                mainContent === "previous" ? renderGratitudePrevious() :
                  mainContent === "account" ? renderAccount() : null}
          </main>
        </div >
      </div >
    </>
  )
}
