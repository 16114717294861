import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import styles from "./DieView.module.scss";
import NavBar from "../home/NavBar.jsx";
import { Helmet } from "react-helmet";
import { MdHourglassEmpty } from "react-icons/md";
import Footer from "../home/Footer.jsx";
import DieOverlayRouter from "./DieOverlayRouter.jsx";
import Drift from "react-driftjs";
import ReactGA from "react-ga";
import { FaTwitter } from "react-icons/fa";

const achievements = {
    michaelPhelps: {
      name: "Michael Phelps",
      age: 15,
      weekNum: 821,
      description: "Breaks his first world record - the 200 m butterfly.",
      imageLink: "https://i.imgur.com/a0BjXMY.png"
    },
    simoneBiles: {
      name: "Simone Biles",
      age: 19,
      weekNum: 1012,
      description:
        "Wins her first gold medal in the Rio Olympics.  Simone ends up with four golds that year.",
      imageLink: "https://i.imgur.com/CmMolSn.jpeg"
    },
    steveJobs: {
      name: "Steve Jobs",
      age: 21,
      weekNum: 1101,
      description: "Along with Steve Wozniak, Steve starts Apple in Los Altos.",
      imageLink: "https://i.imgur.com/9wTezJ9.jpeg"
    },
    jenniferLawrence: {
      name: "Jennifer Lawrence",
      age: 22,
      weekNum: 1175,
      description:
        'Attends the 85th Academy Awards this week.  For her performace in "Silver Linings Playbook", she wins an Oscar for Best Actress.',
      imageLink: "https://i.imgur.com/L0cj3Nv.png"
    },
    albertEinstein: {
      name: "Albert Einstein",
      age: 26,
      weekNum: 1369,
      description:
        "Releases the first of his four groundbreaking papers of 1905.  The year many consider his annus mirabilis (miracle year).",
      imageLink:
        "https://i.imgur.com/IylocTa.jpeg"
    },
    michaelJordan: {
      name: "Michael Jordan",
      age: 28,
      weekNum: 1502,
      description:
        "The week of the '91 NBA Championships, where Michael helps the Bulls take the first of six championships during his career.",
      imageLink: "https://i.imgur.com/wHUY2l7.png"
    },
    billGates: {
      name: "Bill Gates",
      age: 31,
      weekNum: 1617,
      description:
        "Though Microsoft went public when Bill Gates was 28, it was this week in his life that he became a billionaire at 31.",
      imageLink: "https://i.imgur.com/Cw235Mj.jpeg"
    },
    ellenOchoa: {
      name: "Ellen Ochoa",
      age: 34,
      weekNum: 1821,
      description:
        "Takes first mission to space on STS-56 ATLAS-2 Discovery in 1993. Becomes first Hispanic woman to go to space.",
      imageLink: "https://i.imgur.com/c65wbLi.jpeg"
    },
  
    johnLegend: {
      name: "John Legend",
      age: 39,
      weekNum: 2072,
      description:
        "Becomes the youngest person to achieve EGOT status, meaning he's won an Emmy, Grammy, Oscar and Tony.  This week highlights his Emmy win.",
      imageLink: "https://i.imgur.com/oo8qRm0.jpeg"
    },
    saraBlakely: {
      name: "Sara Blakely",
      age: 41,
      weekNum: 2139,
      description:
        "Sara Blakely was 27 when she started the first version of Spanx. But, it was this week in her life that she became the youngest self-made female billionaire at the time.",
      imageLink: "https://i.imgur.com/PVsOwhE.jpeg"
    },
    jfk: {
      name: "JFK",
      age: 43,
      weekNum: 2267,
      description:
        "This week in John F. Kennedy's life marks the end of the 1960 election, when JFK becomes the youngest president in the history of the U.S.",
      imageLink: "https://i.imgur.com/idYhO3y.jpeg"
    },
    barackObama: {
      name: "Barack Obama",
      age: 47,
      weekNum: 2465,
      description:
        "Becomes the 44th president of the U.S. and first black president of the U.S. after the 2008 election.",
      imageLink: "https://i.imgur.com/ThoIcvs.jpeg"
    },
    steveJobsiPhone: {
      name: "Steve Jobs (but older)",
      age: 51,
      weekNum: 2706,
      description:
        'This week marks the first reveal of the iPhone at MacWorld 2007, "a phone, a music player, and a breakthrough internet communications device." ',
      imageLink: "https://i.imgur.com/dPkqzi9.jpeg"
    },
  
    gandhi: {
      name: "Mahatma Gandhi",
      age: 60,
      weekNum: 3152,
      description:
        "Leads the salt march in protest of the British-imposed salt tax.",
      imageLink: "https://i.imgur.com/llk7OPR.jpeg"
    },
    graceHopper: {
      name: "Grace Hopper",
      age: 63,
      weekNum: 3295,
      description:
        "After a lifetime of being a pioneer of computer programming, Grace Hopper develops the implementation of standards for testing computer systems and components for the entirety of the Defense Department",
      imageLink: "https://i.imgur.com/N4LcyG4.jpeg"
    },
    doloresHuerta: {
      name: "Dolores Huerta",
      age: 71,
      weekNum: 3742,
      description:
        "Starts the Dolores Huerta Foundation after a lifetime of fighting for worker's, immigrant's and women's rights",
      imageLink: "https://i.imgur.com/Kj5rjJh.png"
    },
    mayaAngelou: {
      name: "Maya Angelou",
      age: 85,
      weekNum: 4434,
      description:
        'Publishes the seventh volume of her autobiography, titled "Mom & Me & Mom"',
      imageLink: "https://i.imgur.com/JwO9kMQ.jpeg"
    },
    susan: {
      name: "Susan B. Anthony",
      age: 81,
      weekNum: 4272,
      description:
        "Creates the International Woman Suffrage Alliance after a life of pushing the women's suffrage movement.",
      imageLink: "https://i.imgur.com/hBMMBrw.jpeg"
    }
  };

class DieView extends Component {
  constructor() {
    super();
    this.state = {
      displayYear: 0,
      displayWeek: 0,
      passed: 200,
      weeksLeft: 52 * 91 - 200,
      passedBlocks: [],
      notPassedBlocks: [],
      achievementsArray: [],
    };
  }

  componentDidMount() {
    let achievementsArray = [];

    for (var key in achievements) {
      if (achievements.hasOwnProperty(key)) {
        achievementsArray.push(achievements[key]);
      }
    }
    this.setState({ achievementsArray });
    if (this.props.match.params.bday) {
      const passed = parseInt(this.props.match.params.bday);
      this.setState(
        {
          passed: passed,
          weeksLeft: 52 * 91 - passed,
        },
        () => {
          const { passed } = this.state;
          let passedBlocks = new Array(passed);
          let notPassedBlocks = new Array(52 * 91 - passed);
          passedBlocks.fill("poop");
          notPassedBlocks.fill("poop");
          this.setState({
            passedBlocks,
            notPassedBlocks,
          });
        }
      );
    }
  }

  getCellClassName(original, ind, mobile = false) {
    if (mobile) {
      return original;
    }
    if (
      [
        821, 1012, 1101, 1175, 1369, 1502, 1617, 2072, 2139, 2267, 2706, 4434,
        2465, 1821, 3742, 3295, 3152, 4272,
      ].includes(ind)
    ) {
      return styles.milestoneCell;
    }
    return original;
  }

  getOnClick(ind) {
    const { history } = this.props;
    if (ind === 821) {
      history.push(
        "/die/" + parseInt(this.props.match.params.bday) + "/phelps"
      );
      return;
    } else if (ind === 1012) {
      history.push("/die/" + parseInt(this.props.match.params.bday) + "/biles");
      return;
    } else if (ind === 1101) {
      history.push("/die/" + parseInt(this.props.match.params.bday) + "/jobs");
      return;
    } else if (ind === 1175) {
      history.push(
        "/die/" + parseInt(this.props.match.params.bday) + "/lawrence"
      );
      return;
    } else if (ind === 1369) {
      history.push(
        "/die/" + parseInt(this.props.match.params.bday) + "/einstein"
      );
      return;
    } else if (ind === 1502) {
      history.push(
        "/die/" + parseInt(this.props.match.params.bday) + "/jordan"
      );
      return;
    } else if (ind === 1617) {
      history.push("/die/" + parseInt(this.props.match.params.bday) + "/gates");
      return;
    } else if (ind === 2072) {
      history.push(
        "/die/" + parseInt(this.props.match.params.bday) + "/legend"
      );
      return;
    } else if (ind === 2139) {
      history.push(
        "/die/" + parseInt(this.props.match.params.bday) + "/blakely"
      );
      return;
    } else if (ind === 2267) {
      history.push("/die/" + parseInt(this.props.match.params.bday) + "/jfk");
      return;
    } else if (ind === 2706) {
      history.push(
        "/die/" + parseInt(this.props.match.params.bday) + "/jobsphone"
      );
      return;
    } else if (ind === 4434) {
      history.push("/die/" + parseInt(this.props.match.params.bday) + "/maya");
      return;
    } else if (ind === 2465) {
      history.push("/die/" + parseInt(this.props.match.params.bday) + "/obama");
      return;
    } else if (ind === 1821) {
      history.push("/die/" + parseInt(this.props.match.params.bday) + "/ochoa");
      return;
    } else if (ind === 3742) {
      history.push(
        "/die/" + parseInt(this.props.match.params.bday) + "/dolores"
      );
      return;
    } else if (ind === 3295) {
      history.push("/die/" + parseInt(this.props.match.params.bday) + "/grace");
      return;
    } else if (ind === 3152) {
      history.push(
        "/die/" + parseInt(this.props.match.params.bday) + "/gandhi"
      );
      return;
    } else if (ind === 4272) {
      history.push("/die/" + parseInt(this.props.match.params.bday) + "/susan");
      return;
    }

    return;
  }

  goToHome() {
    const { history } = this.props;

    ReactGA.event({
      category: "Navigation",
      action: "Home from Die - Top",
    });

    history.push("/");

  }

  renderBlocks(showInfo = true, mobile = false) {
    const { passedBlocks, notPassedBlocks } = this.state;

    return (
      <>
        <div className={styles.outerChart}>
          <div className={styles.chartContainer}>
            {showInfo ? (
              <div>
                <div className={styles.stickyInfoContainer}>
                  <div className={styles.sticky}>
                    <div>
                      Year: <b>{this.state.displayYear}</b>
                    </div>
                    <div>
                      Week: <b>{this.state.displayWeek}</b>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div className={styles.cellContainer}>
              {passedBlocks.map((el, ind) => (
                <div
                  key={ind}
                  className={this.getCellClassName(
                    styles.passedCell,
                    ind,
                    mobile
                  )}
                  onMouseEnter={() => {
                    this.setState({
                      displayYear: Math.floor(ind / 52),
                      displayWeek: ind % 52,
                    });
                  }}
                  onClick={() => this.getOnClick(ind)}
                ></div>
              ))}
              {notPassedBlocks.map((el, ind) => (
                <div
                  key={ind}
                  className={this.getCellClassName(
                    styles.notPassedCell,
                    ind + this.state.passed,
                    mobile
                  )}
                  onMouseEnter={() => {
                    this.setState({
                      poo: ind + this.state.passed,
                      displayYear: Math.floor((ind + this.state.passed) / 52),
                      displayWeek: (ind + this.state.passed) % 52,
                    });
                  }}
                  onClick={() => this.getOnClick(ind + this.state.passed)}
                ></div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
  renderHelmet() {
    return (
      <Helmet>
        <title>How many weeks until you die?</title>
        <meta
          name="description"
          content="Find out how many more weeks you have to live"
        />
        <meta name="keywords" content="weeks die, inspiring, chart of life" />
        <meta property="og:url" content="https://www.failflow.com/die" />
        <meta property="og:title" content="How many weeks until you die?" />
        <meta
          property="og:description"
          content="Find out how many more weeks you have to live!"
        />
        <meta property="og:image" content="https://i.imgur.com/NuWGTgh.png" />
        <meta
          property="og:image:url"
          content="https://i.imgur.com/NuWGTgh.png"
        />
        <meta
          property="twitter:image"
          content="https://i.imgur.com/NuWGTgh.png"
        />
      </Helmet>
    );
  }
  renderHeros() {
    const { achievementsArray } = this.state;

    return (
      <div className={styles.heroBackground}>
        <div className={styles.outerChart}>
          <div className={styles.infoPreHeader}>How are others doing?</div>
          <div className={styles.infoHeader}>
            Impressive weeks for impressive people
          </div>
          <div className={styles.infoDescHide}>
            List view of the accomplishments from the graph above
          </div>
          <div className={styles.heroCardContainer}>
            {achievementsArray.map((hero) => (
              <div key={hero.weekNum} className={styles.heroCard}>
                <div className={styles.heroImageContainer}>
                  <img
                    src={hero.imageLink}
                    alt={hero.name}
                    className={styles.heroImage}
                  ></img>
                </div>
                <div className={styles.heroText}>
                  <div className={styles.heroName}>{hero.name}</div>
                  <div className={styles.heroAge}>
                    Week of their life:{" "}
                    <b>{hero.weekNum.toLocaleString("en")}</b>
                  </div>
                  <div className={styles.heroAge}>
                    Age that week: <b>{hero.age}</b>
                  </div>
                  <div className={styles.heroAccomplishmentLabel}>
                    Accomplishment:
                  </div>
                  <div className={styles.heroDesc}>{hero.description}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { weeksLeft } = this.state;
    return (
      <div>
        {this.renderHelmet()}
        <NavBar />
        <Drift appId="pbn5kccpcwrr"></Drift>
        <div className={styles.outerMargin}>
          <div className={styles.infoContainer}>
            <div className={styles.infoHeader}>
              You will die in <b>{weeksLeft.toLocaleString("en")}</b> weeks
            </div>
            <div className={styles.infoDesc}>
              Each cell represents 1 week of your life. The chart below spans 52
              cells across representing the weeks in a given year, and 91 cells
              down, representing the (hopefully) 91 years of your life.
            </div>
            <div className={styles.infoLegend}>
              <div className={styles.legendRow}>
                <div className={styles.passedLegend}></div> Week already Lived
              </div>
              <div className={styles.legendRow}>
                <div className={styles.notPassedLegend}></div> Week not lived
                yet
              </div>
              <div className={styles.legendRowMilestone}>
                <div className={styles.milestoneLegend}></div> Click to see a
                famous milestone
              </div>
            </div>
          </div>
         
        </div>

        <div className={styles.blocksNotMobile}>
          {this.renderBlocks(true, false)}
        </div>
        <div className={styles.blocksMobile}>
          {this.renderBlocks(true, true)}
        </div>

        <div className={styles.quoteBackground}>
          <div className={styles.quote}>
            <a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fwww.failflow.com%2Flife&via=shahgaleeb&text=Wow%2C%20I%20don%27t%20have%20many%20weeks%20left%20to%20live%21">
              <div className={styles.twitterHold}>
                <div className={styles.twitterText}>Share on</div>
                <FaTwitter className={styles.twitterIcon}></FaTwitter>
              </div>
            </a>
            <div className={styles.bar}></div>
            <i>
              There's nothing quite like contemplating the finiteness of life to
              help you to stop procrastinating.
            </i>
            <div className={styles.bar}></div>

          </div>
        </div>
        <div className="bg-blue-700">
          <div className="mx-auto max-w-2xl py-16 px-4 text-center sm:py-20 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              <span className="block">Start living more.</span>
              <span className="block">Before you die.</span>
            </h2>
            <p className="mt-4 text-lg leading-6 text-indigo-200">
              Sign up for free before we launch later this year.</p>
            <div
              className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-blue-600 hover:bg-blue-50 sm:w-auto hover:cursor-pointer"
              onClick={() => {
                ReactGA.event({
                  category: "Navigation",
                  action: "Signup from Die - Mid",
                });
                window.location = "/signup"
              }}
            >
              Sign up for free
            </div>

          </div>
        </div>
        <div className={styles.popback}>
          <div className={styles.sectionContainer}>
            <div className={styles.outerChart}>
              <div className={styles.infoPreHeader}>By the numbers</div>
              <div className={styles.infoHeader}>Your life at a glance</div>
              <div className={styles.glanceContainer}>
                <div className={styles.glanceChart}>
                  {this.renderBlocks(false, true)}
                </div>
                <div className={styles.glanceInfoContainer}>
                  <div className={styles.infoRow}>
                    <div className={styles.iconContainer}>
                      <MdHourglassEmpty
                        className={styles.hourglassIcon}
                      ></MdHourglassEmpty>{" "}
                    </div>
                    <div>
                      You're on week{" "}
                      <b>{(this.state.passed + 1).toLocaleString("en")}</b> of{" "}
                      <b>{(91 * 52).toLocaleString("en")}</b> of your life
                    </div>
                  </div>
                  <div className={styles.infoRow}>
                    <div className={styles.iconContainer}>
                      <MdHourglassEmpty
                        className={styles.hourglassIcon}
                      ></MdHourglassEmpty>{" "}
                    </div>
                    <div>
                      This estimate assumes you'll die when you're <b>91</b>,
                      but if you live in the U.S. the{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.ssa.gov/OACT/population/longevity.html"
                      >
                        Office of Social Security
                      </a>{" "}
                      thinks you'll die at around <b>83</b>
                    </div>
                  </div>
                  <div className={styles.infoRow}>
                    <div className={styles.iconContainer}>
                      <MdHourglassEmpty
                        className={styles.hourglassIcon}
                      ></MdHourglassEmpty>{" "}
                    </div>

                    <div>
                      {" "}
                      Most people work between the age of <b>22 to 65</b>,
                      meaning we spend about <b>2,236</b> weeks in the "career"
                      phase
                    </div>
                  </div>

                  <div className={styles.infoRow}>
                    <div className={styles.iconContainer}>
                      <MdHourglassEmpty
                        className={styles.hourglassIcon}
                      ></MdHourglassEmpty>{" "}
                    </div>
                    <div>
                      {" "}
                      Most people will spend about <b>936</b> weeks in
                      retirement. Triple digits
                    </div>
                  </div>
                  <div className={styles.infoRow}>
                    <div className={styles.iconContainer}>
                      <MdHourglassEmpty
                        className={styles.hourglassIcon}
                      ></MdHourglassEmpty>{" "}
                    </div>
                    <div>
                      {" "}
                      If you sleep 8 hours a day, you will likely spend more
                      than <b>1,500</b> weeks of your life asleep
                    </div>
                  </div>
                  <div className={styles.infoRow}>
                    <div className={styles.iconContainer}>
                      <MdHourglassEmpty
                        className={styles.hourglassIcon}
                      ></MdHourglassEmpty>{" "}
                    </div>
                    <div>
                      {" "}
                      On average, women get <b>364</b> more weeks of life than
                      men
                    </div>
                  </div>
                  <div className={styles.infoRow}>
                    <div className={styles.iconContainer}>
                      <MdHourglassEmpty
                        className={styles.hourglassIcon}
                      ></MdHourglassEmpty>{" "}
                    </div>

                    <div>
                      {" "}
                      If it takes 50 weeks to become advanced at any skill, you
                      can learn about <b>44</b> new skills during your career or{" "}
                      <b>64</b> if you include retirement.{" "}
                      <a href="https://skillshare.eqcm.net/c/2876379/1000759/4650">
                        Might as well pick something for this week (SkillShare)
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderHeros()}
        <div className={styles.popback}>
          <div className={styles.sectionContainer}>
            <div className={styles.outerChart}>
              <div className={styles.infoPreHeader}>Learn more</div>
              <div className={styles.infoHeader}>How was this made?</div>
              <div className={styles.paragraph}>
                <div className={styles.infoRow}>
                  <div className={styles.iconContainer}>
                    <MdHourglassEmpty
                      className={styles.hourglassIcon}
                    ></MdHourglassEmpty>{" "}
                  </div>
                  <div>
                    {" "}
                    This site was based on an a post from
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://waitbutwhy.com/2015/12/the-tail-end.html"
                    >
                      {" "}
                      Tim Urban's, Wait But Why
                    </a>{" "}
                    and
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.youtube.com/watch?v=MBRqu0YOH14"
                    >
                      {" "}
                      Kurzgesagt's video, Optimistic Nihilism
                    </a>{" "}
                  </div>
                </div>

                <div className={styles.infoRow}>
                  <div className={styles.iconContainer}>
                    <MdHourglassEmpty
                      className={styles.hourglassIcon}
                    ></MdHourglassEmpty>{" "}
                  </div>
                  <div>
                    {" "}
                    Orginally, I had a personal chart on{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://galeeb.com"
                    >
                      {" "}
                      galeeb.com{" "}
                    </a>{" "}
                    and visitors either thought it was awesome or very scary. So
                    I thought let's let others see their own!
                  </div>
                </div>
                <div className={styles.infoRow}>
                  <div className={styles.iconContainer}>
                    <MdHourglassEmpty
                      className={styles.hourglassIcon}
                    ></MdHourglassEmpty>{" "}
                  </div>
                  <div>
                    This site was built using React which I learned working at{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.kapwing.com"
                    >
                      {" "}
                      Kapwing{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Link to="/tools">
          <div className={styles.bigButton}>
            Check out other FailFlow projects{" "}
          </div>
        </Link>
        <Footer></Footer>
        <DieOverlayRouter></DieOverlayRouter>
      </div>
    );
  }
}

export default withRouter(DieView);
