export const setToken = (token) => ({
  type: "SET_TOKEN",
  token,
});

export const setUser = (userData) => ({
  type: "SET_USER",
  userData,
});

export const logOut = (userData) => ({
  type: "LOG_OUT",
  userData,
});

export const locallyUpgradeUser = () => ({
  type: "LOCALLY_UPGRADE_USER",
});
