import { combineReducers } from "redux";
import user from "./user";
import toast from "./toast";
import expert from "./expert";

export default combineReducers({
  user,
  toast,
  expert,
});
